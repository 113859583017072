export default {
  step1: {
    title: "Reservierung / Auto wählen / Buchen",
    text1: "Buchen Sie jetzt!",
    text2: "Anmietort:",
    text2opt: ["Flughafen von Thessaloniki", "nach Vereinbarung"],
    text3: "Anmietdatum:",
    text4:
      "Bei Anlieferung und Rückgabe des Mietwagens außerhalb der Öffnungszeiten (08:00 – 21:00 Uhr) wird ein Zuschlag in Höhe von €20,00 berechnet",
    text5: "Rückgabeort:",
    text6: "Rückgabedatum:",
    text7:
      "Bei Anlieferung und Rückgabe des Mietwagens außerhalb der Öffnungszeiten (08:00 – 21:00 Uhr) wird ein Zuschlag in Höhe von €20,00 berechnet",
  },
  step2: {
    title: "Wählen Auto",
    category: "Kategorie",
    insurance: "Full Insurance Coverage (VOLLKASKO)",
    insurance1a: "Vollkasko mit reduzierter Haftung - €600.",
    insurance1b: "Verwendung einer Kreditkarte oder einer Garantie von 600€",
    insurance2a: "Vollkasko mit reduzierter Haftung - €200.",
    insurance2b: "Verwendung einer Kreditkarte oder einer Garantie von 200€",
    insurance3a: "Vollkasko ohne jegliche Selbstbeteiligung (0€).",
    insurance3b: "KEINE KREDITKARTE ODER GARANTIE IST ERFORDERLICH",
    babyseat: "BABY SEAT",
    gps: "GPS (Extra €25.00)",
  },
  step3: {
    title: "Überprüfen & Buchen",
    firstName: "VOR",
    lastName: "NACHNAME",
    flight: "FLUGNUMMER",
    email: "Email-Adresse",
    telephone: "TELEFONNUMMER",
    comments: "KOMMENTARE",
    submit: "ABSCHICKEN",
  },
  reservation: {
    title: "IHRE RESERVIERUNG", // panel_header
    total: "Gesamtbetrag",
    extra: "Extras",
    days: "Tage",
  },
  nav: {
    previous: "ZURÜCK",
    next: "WEITER",
  },
};
