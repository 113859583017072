export default {
  offer: {
    title: "We offer",
    options1: "Unlimited klms",
    options2: "VAT 24%",
    options3: "Full Damage Waiver (FDW)",
    options4: "Theft Protection Insurance",
    options5: "Additional Driver",
    options6: "24-hours road assistance",
    options7: "Free of charge pick-ups and deliveries at the airport",
  },
  insurance: {
    title: "The insurance we provide",
    text1: "Optionally we can provide Full Damage Waiver regardless of:",
    option1: "the vehicle category or",
    option2: "the rental period and the rental duration",
    text2: "...so that in case of an accident you will not be charged at all.",
  },
  equipment: {
    title: "Equipment of our vehicles",
    text: "All vehicles have 5 doors and are fully equipped as follows:",
    option1: "Air condition",
    option2: "Power steering",
    option3: "Power windows",
    option4: "CD-player",
  },
  services: {
    title: "Services",
    text: "Transfer services from and to Thessaloniki with all kind of vehicles",
  },
  trademark: "NOVA RENT A CAR LLC",
};
