import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  header: {
    paddingBottom: 50,
    "& img": {
      width: "50%",
    },
    [theme.breakpoints.down("md")]: {
      paddingBottom: 15,
      "& img": {
        width: "90%",
      },
    },
  },
  diexodos: {
    paddingBottom: 30,
    "& img": {
      width: "30%",
    },
    [theme.breakpoints.down("md")]: {
      paddingBottom: 15,
      "& img": {
        width: "50%",
      },
    },
  },
  covid: {
    paddingBottom: 15,
    "& img": {
      width: "90%",
    },
    [theme.breakpoints.down("md")]: {
      paddingBottom: 5,
      "& img": {
        width: "100%",
      },
    },
  },
  footer: {
    paddingTop: 50,
    "& img": {
      width: "50%",
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: 15,
      "& img": {
        width: "90%",
      },
    },
  },
  img: {
    padding: "30px 0",
    width: "100%",
    maxWidth: 400,
    height: "auto",
  },
  modal: {
    position: "absolute",
    maxWidth: 600,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[1],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
    fontSize: "0.7rem",
    [theme.breakpoints.down("md")]: {
      width: "70%",
      fontSize: "0.6rem",
    },
  },
}));

export { useStyles };
