import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardMedia, // CardMedia,
} from "@material-ui/core";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import locale from "./Locale";
import style from "./Style";
import { ContainerProps } from "../types";

const host = "https://www.novarentacar.gr/api";

const Component = ({ lang }: ContainerProps) => {
  const [fleetCategory, setFleetCategory] = useState([]);

  const getFleetCategory = async () => {
    const response = await fetch(`${host}/`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        p: "fleetCategory",
      }), // body data type must match "Content-Type" header
    });
    const result = await response.json();
    setFleetCategory(result.data);
  };

  useEffect(() => {
    getFleetCategory();
  }, []);

  return (
    <>
      <Header lang={lang} page="fleet" />

      <Grid container spacing={5} style={style.container}>
        <Grid item xs={12}>
          <h1>{locale[lang].title}</h1>
        </Grid>

        {fleetCategory.map((row, index) => (
          <Grid
            container
            spacing={5}
            key={`${row.fleet_category_id}-${index}`}
            style={style.row}
          >
            <Grid item xs={12}>
              <h3>
                {locale[lang].category} {row.title}
              </h3>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card>
                <CardContent style={style.card.header}>
                  {row.description}
                </CardContent>
                <CardContent style={style.card.media}>
                  <CardMedia
                    component="img"
                    alt={row.title}
                    height="100%"
                    width="100%"
                    src={`images/fleet/${row.img1}`}
                    title={row.title}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card>
                <CardContent style={style.card.header}>
                  {row.description}
                </CardContent>
                <CardContent style={style.card.media}>
                  <CardMedia
                    component="img"
                    alt={row.title}
                    height="100%"
                    width="100%"
                    src={`images/fleet/${row.img2}`}
                    title={row.title}
                    style={{ padding: 0, margin: 2 }}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        ))}
      </Grid>

      <Footer lang={lang} page="fleet" />
    </>
  );
};

export default Component;
