const style = {
  container: {
    padding: 50,
  },
  row: {
    paddingBottom: 50,
  },
  card: {
    borderColor: "#cccccc",
    maxHeight: 300,
    header: {
      fontSize: 16,
      backgroundColor: "#e6e6e6",
    },
    content: {
      // maxHeight: 10,
    },
    media: {
      padding: 0,
    },
    media2: {
      weight: "100%",
      height: "100%",
      maxheight: 320,
    },
    img: {
      width: "100%",
      height: "auto",
    },
  },
};

export default style;
