// import theme from '../Theme';
import { makeStyles } from "@material-ui/core/styles";
// import theme from '../../../config/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid #cccccc",
    width: "100%",
    marginBottom: 50,
  },
  header: {
    backgroundColor: "#e6e6e6",
    borderBottom: `5px solid ${theme.palette.primary.main}`,
  },
  content: {
    backgroundColor: "#f5f5f5",
    padding: 30,
  },
  stepper: {
    backgroundColor: "rgba(0,0,0,0)",
  },
  formControl: {
    display: "block",
    minWidth: 120,
    paddingBottom: 15,
  },
  formControlStacked: {
    display: "inline-block",
    paddingBottom: 15,
  },
  formGrid: {},
  formGridFrom: {
    paddingRight: 30,
    borderRight: "1px solid #CCCCCC",
  },
  formGridTo: {
    paddingLeft: 30,
  },
  reservation: {
    border: "1px solid #CCCCCC",
  },
  reservationModalHeader: {
    backgroundColor: theme.palette.primary.main,
    padding: "5px 15px",
    color: "#FFFFFF",
    marginBottom: 15,
  },
  reservationModalContentP: {
    padding: "5px 15px",
  },
  reservationModalContent: {
    padding: "0 15px",
  },
  reservationModalFooter: {
    padding: "5px 15px",
    backgroundColor: theme.palette.grey[200],
    marginTop: 15,
  },
  categoryLabel: {
    paddingTop: 20,
    border: 0,
  },
  categoryImage: {
    border: "1px solid #cccccc",
    height: 80,
  },
  insuranceCol1: {
    "& > span": {
      padding: 4,
    },
    maxWidth: 35,
  },
  insuranceCol2: {
    alignSelf: "center",
  },
  card: {
    media: {
      padding: 0,
    },
    img: {
      width: "100%",
      height: "auto",
    },
  },
  footnote: {
    paddingTop: 30,
    fontSize: "0.7rem",
  },
  footer: {
    borderTop: `1px solid ${theme.palette.grey[400]}`,
    marginTop: 30,
    paddingTop: 30,
  },
  [theme.breakpoints.down("md")]: {
    formGridFrom: {
      paddingRight: 0,
      borderRight: 0,
      paddingBottom: 30,
    },
    formGridTo: {
      borderTop: `1px solid ${theme.palette.grey[400]}`,
      paddingLeft: 0,
      paddingTop: 30,
      paddingBottom: 30,
    },
  },
  // [theme.breakpoints.down('sm')]: {
  //   formGridFrom: {
  //     borderRight: 0,
  //   },
  // },
  icon: {
    // fill: theme.palette.primary.main,
    fill: theme.palette.grey[400],
  },
  form: {
    paddingBottom: 30,
  },
}));

export default useStyles;
