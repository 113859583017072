import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import locale from "./Locale";
import style from "./Style";
import { ContainerProps } from "../types";

const Container = ({ lang }: ContainerProps) => (
  <>
    <Header lang={lang} page="about_us" />

    <Grid container spacing={3} style={style.container}>
      <Grid item xs={6}>
        <p>{locale[lang].text1}</p>
        <p>{locale[lang].text2}</p>
        <p>{locale[lang].text3}</p>
        <p>{locale[lang].text4}</p>
      </Grid>
    </Grid>

    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11923.026208757221!2d22.983887751445202!3d40.522618132811694!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a8156f3ea76e91%3A0xbd97a8ea16ca97e7!2sNova%20Rent%20a%20Car%20Ltd!5e0!3m2!1sen!2sus!4v1582381228024!5m2!1sen!2sus"
      width={document.body.clientWidth}
      height="450"
      frameBorder="0"
      style={{ border: 0 }}
      allowFullScreen=""
      title="Nova Rent a Car location"
    />

    <Grid container spacing={3} style={style.container}>
      <Grid item xs={6}>
        <p>{locale[lang].espa1}</p>
        <p>{locale[lang].espa2}</p>
        <p>{locale[lang].espa3}</p>
      </Grid>
    </Grid>

    <Footer lang={lang} page="about_us" />
  </>
);

Container.propTypes = {
  lang: PropTypes.string,
};

Container.defaultProps = {
  lang: "en",
};

export default Container;
