const locale = {
  en: {
    text1:
      "Nova rent a car is a well established car rental company that operates in the broader region of Northern Greece since 2003. Its main goal is to provide excellent customer service along with the most competitive prices. By offering you full insurance cover.",
    text2:
      "We are based in Thessaloniki and offer service for all customers at Macedonia Airport as well as anywhere else in the city, thereby helping you avoid any delays that might cause problems to your business trip or holidays.",
    text3:
      "We offer you services of highest quality and leave to you only the driving…",
    text4: "How to get to Nova Rent a Car",
    espa1:
      'Τhe enterprise Nova Reant a Car LLC ith headquarters in the Region of Central Macedonia, was co-financed by the European Regional Development Fund (ERDF) of the European Union (ΕΕ) for the implementation of its project under the Action “"Support micro and small enterprises affected by Covid-19 in Central Macedonia“ under the framework of the Operational Program «Central Macedonia» 2014-2020.',
    espa2:
      "The Action is aimed at empowering micro and small enterprises in the Region of Central Macedonia in the form of a non-repayable grant to ensure sufficient liquidity to deal with the effects of the Covid-19 pandemic.",
    espa3:
      "Ιnvestment’s total budget is 50000,00 € (100% public expenditure). The Action is co-financed by Greece and the European Union - European Regional Development Fund.",
  },
  gr: {
    text1:
      "Η NOVA RENT A CAR είναι μια καλά εδραιωμένη εταιρεία ενοικιάσεως αυτοκινήτων, η οποία δραστηριοποιείται στην ευρύτερη περιοχή της Βορείου Ελλάδος. Σημείο αναφοράς της Nova Rent a Car είναι η άψογη εξυπηρέτηση των πελατών και οι πολύ ανταγωνιστικές τιμές.",
    text2:
      "Με έδρα την Θεσσαλονίκη επιτυγχάνεται η άμεση εξυπηρέτησή σας στο αεροδρόμιο Μακεδονίας, αλλά και σε οποιοδήποτε άλλο σημείο της πόλης, αποφεύγοντας τυχόν καθυστερήσεις που μπορούν να δημιουργήσουν προβλήματα στο επαγγελματικό ή στο ταξίδι αναψυχής σας.",
    text3:
      "Με σκοπό την ποιοτική εξυπηρέτηση των πελατών μας, καθώς και την υψηλού επιπέδου παροχή υπηρεσιών, αφήνουμε σε εσάς μόνο το κομμάτι της οδήγησης...",
    text4: "Πώς θα μας βρείτε",
    espa1:
      "Η επιχείρηση ΕΝΟΙΚΙΑΣΕΙΣ ΑΥΤΟΚΙΝΗΤΩΝ ΝΟΒΑ ΕΤΑΙΡΙΑ ΠΕΡΙΟΡΙΣΜΕΝΗΣ ΕΥΘΥΝΗΣ που εδρεύει στην Περιφέρεια Κεντρικής Μακεδονίας, εντάχθηκε στη Δράση «Ενίσχυση μικρών και πολύ μικρών επιχειρήσεων που επλήγησαν από τον Covid-19 στην Κεντρική Μακεδονία» συνολικού προϋπολογισμού 200 εκ. ευρώ.",
    espa2:
      "Η Δράση στοχεύει στην παροχή ενίσχυσης των πολύ μικρών και μικρών επιχειρήσεων της Περιφέρειας Κεντρικής Μακεδονίας με τη μορφή της μη επιστρεπτέας επιχορήγησης για τη διασφάλιση επαρκούς ρευστότητας για την αντιμετώπιση των επιπτώσεων της πανδημίας Covid-19.",
    espa3:
      "Ο συνολικός προϋπολογισμός (100% Δημόσια Δαπάνη) της επένδυσης είναι 50000,00 €, και συγχρηματοδοτείται από το Ευρωπαϊκό Ταμείο Περιφερειακής Ανάπτυξης (ΕΤΠΑ) της Ευρωπαϊκής Ένωσης (ΕΕ) και Εθνικούς Πόρους στο πλαίσιο του Επιχειρησιακού Προγράμματος «Κεντρική Μακεδονία» 2014-2020.",
  },
  de: {
    text1:
      "NOVA RENT A CAR ist ein gut etabliertes Autovermietungsunternehmen, das in der Region Nordgriechenlands tätig ist. Ziel von Nova Rent a Car ist, einen exzellenten Kundenservice und sehr wettbewerbsfähige und günstige Preise anzubieten. In Kombination mit einer Vollkasko-Versicherung möchte unser Unternehmen Ihnen einen stressfreien und sicheren Urlaub versprechen.",
    text2:
      "Mit Hauptsitz in Thessaloniki liefern wir Ihnen den Mietwagen am Flughafen oder anderswo in Thessaloniki schnell, zuverlässig und kostenlos, sodass sie Ihre Urlaubsreise bzw. Betriebsreise angenehm gestalten können.",
    text3:
      "Durch den qualitativ hochwertigen Kundendienst und die hohe Servicequalität überlassen wir Ihnen nur den Teil der Fahrt!",
    text4: "Wo Sie uns finden",
  },
  it: {
    text1:
      "Nova Rent a Car è una azienda di autonoleggio che opera nell' area della Grecia del Nord. I suoi punti di riferimento sono il servizio clienti eccellente ed i prezzi molto competitivi. In combinazione con l' assicurazione completa (KASKO TOTALE) cerca di alleviare lo stress di trovare un veicolo a noleggio e prometere delle vacanze spensierate.",
    text2:
      "Con sede a Salonicco offriamo un servizio eccellente all' aeroporto di Macedonia ed a qualsiasi altro punto della città evitando ritardi che possono causare problemi nel tuo viaggio professionale o viaggio di piacere.",
    text3:
      "Ci prendiamo cura della ottima qualità del servizio ai nostri clienti in modo che loro pensino solo di guidare…",
    text4: "Come raggiungerci",
  },
};

export default locale;
