export default {
  offer: {
    title: "Wir bieten an",
    options1: "Unbegrenzte Kilometer",
    options2: "MwSt. 24%",
    options3: "Vollkaskoversicherung (ohne Selbstbeteiligung)",
    options4: "Diebstahlversicherung",
    options5: "Zusatzfahrer",
    options6: "Stunden Pannendienst",
    options7:
      "Kostenlose Anlieferung und Abholung des Mietwagens am Flughafen von Thessaloniki",
  },
  insurance: {
    title: "Versicherung",
    text1:
      "Falls Sie sich dafür entscheiden, bieten wir Ihnen Vollversicherung (Vollkasko ohne Selbstbeteiligung) an, unabhängig von:",
    option1: "der Kategorie des Mietwagens, den Sie auswählen",
    option2: "der Periode und Mietdauer",
    text2:
      "...ohne jegliche finanzielle Einbindung des Kunden im Fall eines Unfalls!",
  },
  equipment: {
    title: "Ausstattung unserer Wagen",
    text: "Alle Fahrzeuge haben 5 Türen und sind komplett ausgestattet mit:",
    option1: "Klimaanlage (Air Condition)",
    option2: "Servolenkung",
    option3: "Elektrische Fensterheber",
    option4: "Radio-CD Player",
  },
  services: {
    title: "Services",
    text: "Transfer services from and to Thessaloniki with all kind of vehicles",
  },
  trademark: "NOVA RENT A CAR LLC",
};
