import React from "react";
import PropTypes from "prop-types";
import { Grid, Container } from "@material-ui/core"; // InputLabel, OutlinedInput,
import { makeStyles } from "@material-ui/core/styles";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

const locale = {
  gr: {
    thankyou: {
      thankyou: "Σας ευχαριστούμε!",
      reservationSend: "Η Κράτηση σας έχει σταλλεί!",
      willContactYou:
        "Ένας από τους συνεργάτες μας θα επικοινωνήσει μαζί σας για να επιβεβαιώσει τη κράτηση.",
    },
  },
  en: {
    thankyou: {
      thankyou: "Thank you!",
      reservationSend: "Your Reservation has been send.",
      willContactYou:
        "One of our associates will contact you to confirm the reservation.",
    },
  },
  de: {
    thankyou: {
      thankyou: "Thank you!",
      reservationSend: "Your Reservation has been send.",
      willContactYou:
        "One of our associates will contact you to confirm the reservation.",
    },
  },
  it: {
    thankyou: {
      thankyou: "Thank you!",
      reservationSend: "Your Reservation has been send.",
      willContactYou:
        "One of our associates will contact you to confirm the reservation.",
    },
  },
};

const useStyles = makeStyles(() => ({
  root: {
    border: "1px solid #cccccc",
    width: "100%",
    marginTop: 50,
    marginBottom: 50,
    paddingTop: 50,
    paddingBottom: 50,
    backgroundColor: "#f5f5f5",
  },
}));

const Contact = ({ lang, type }) => {
  const classes = useStyles();

  return (
    <>
      <Header lang={lang} page="contact" />

      <Container fixed maxWidth="sm" className={classes.root}>
        {type === "thankyou" && (
          <Grid item xs={12}>
            <p>{locale[lang][type].thankyou}</p>
            <p>{locale[lang][type].reservationSend}</p>
            <p>{locale[lang][type].willContactYou}</p>
          </Grid>
        )}
      </Container>

      <Footer lang={lang} page="contact" />
    </>
  );
};

Contact.propTypes = {
  lang: PropTypes.string,
  type: PropTypes.string,
};

Contact.defaultProps = {
  lang: "en",
  type: "thankyou",
};

export default Contact;
