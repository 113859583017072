import en from "./Locale/en";
import gr from "./Locale/gr";
import de from "./Locale/de";
import it from "./Locale/it";

export default {
  en,
  gr,
  de,
  it,
};
