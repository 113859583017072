import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom"; // useLocation
import {
  Grid,
  ButtonGroup,
  MenuItem,
  FormControl,
  Select,
  Menu,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { useStyles, MenuButton, MenuButtonMobile } from "./HeaderStyles";
import Locale from "./Locale";
import { ContainerProps } from "../types";

const Container = ({ page, lang }: ContainerProps) => {
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const goTo = (_page, _lang) => {
    const location = `/${_page}.${_lang}.htm`;
    // Add GA Event
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "spaEvent",
      eventCategory: "Header",
      eventAction: "Click",
      eventLabel: location,
    });
    history.push(location);
  };

  const handleChange = (event) => goTo(page, event.target.value);

  const handleChangeMobile = (ln: string) => {
    goTo(page, ln);
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="row"
        justify="flex-end"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Grid
            container
            spacing={0}
            direction="row"
            justify="flex-end"
            alignItems="center"
            className={classes.menuDesktop}
          >
            <ButtonGroup
              variant="text"
              color="primary"
              aria-label="text primary button group"
              ref={anchorRef}
            >
              <MenuButton
                className={page === "index" ? "active" : ""}
                onClick={() => goTo("index", lang)}
              >
                {Locale[lang].index.menu}
              </MenuButton>
              <MenuButton
                className={page === "fleet" ? "active" : ""}
                onClick={() => goTo("fleet", lang)}
              >
                {Locale[lang].fleet.menu}
              </MenuButton>
              <MenuButton
                className={page === "about_us" ? "active" : ""}
                onClick={() => goTo("about_us", lang)}
              >
                {Locale[lang].about_us.menu}
              </MenuButton>
              <MenuButton
                className={page === "terms_and_conditions" ? "active" : ""}
                onClick={() => goTo("terms_and_conditions", lang)}
              >
                {Locale[lang].terms_and_conditions.menu}
              </MenuButton>
              <MenuButton
                className={page === "contact" ? "active" : ""}
                onClick={() => goTo("contact", lang)}
              >
                {Locale[lang].contact.menu}
              </MenuButton>
            </ButtonGroup>
            <FormControl required>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                value={lang}
                onChange={handleChange}
                className={classes.formControl}
              >
                <MenuItem value="gr">
                  <img src="images/flags/gr.png" alt="gr" />
                </MenuItem>
                <MenuItem value="en" selected>
                  <img src="images/flags/gb.png" alt="en" />
                </MenuItem>
                <MenuItem value="de">
                  <img src="images/flags/de.png" alt="de" />
                </MenuItem>
                <MenuItem value="it">
                  <img src="images/flags/it.png" alt="it" />
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={0}
        direction="row"
        justify="flex-end"
        alignItems="center"
        className={classes.menuMobile}
      >
        <MenuButton variant="outlined" onClick={handleClick}>
          <MenuIcon />
        </MenuButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          // className={classes.menuMobilePopup}
        >
          <MenuButtonMobile onClick={() => goTo("index", lang)}>
            {Locale[lang].index.menu}
          </MenuButtonMobile>
          <MenuButtonMobile onClick={() => goTo("fleet", lang)}>
            {Locale[lang].fleet.menu}
          </MenuButtonMobile>
          <MenuButtonMobile onClick={() => goTo("about_us", lang)}>
            {Locale[lang].about_us.menu}
          </MenuButtonMobile>
          <MenuButtonMobile onClick={() => goTo("terms_and_conditions", lang)}>
            {Locale[lang].terms_and_conditions.menu}
          </MenuButtonMobile>
          <MenuButtonMobile onClick={() => goTo("contact", lang)}>
            {Locale[lang].contact.menu}
          </MenuButtonMobile>
          <Grid container>
            <Grid item xs={3}>
              <MenuButton
                className={lang === "gr" ? "active" : ""}
                onClick={() => handleChangeMobile("gr")}
              >
                <img src="images/flags/gr.png" alt="gr" />
              </MenuButton>
            </Grid>
            <Grid item xs={3}>
              <MenuButton
                className={lang === "en" ? "active" : ""}
                onClick={() => handleChangeMobile("en")}
              >
                <img src="images/flags/gb.png" alt="en" />
              </MenuButton>
            </Grid>
            <Grid item xs={3}>
              <MenuButton
                className={lang === "de" ? "active" : ""}
                onClick={() => handleChangeMobile("de")}
              >
                <img src="images/flags/de.png" alt="de" />
              </MenuButton>
            </Grid>
            <Grid item xs={3}>
              <MenuButton
                className={lang === "it" ? "active" : ""}
                onClick={() => handleChangeMobile("it")}
              >
                <img src="images/flags/it.png" alt="it" />
              </MenuButton>
            </Grid>
          </Grid>
        </Menu>
      </Grid>

      <Grid
        container
        spacing={0}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        className={classes.jumbotron}
      >
        <Grid item xs={12} className={classes.title}>
          {Locale[lang][page].title}
        </Grid>
        <Grid item xs={12} className={classes.subTitle}>
          {Locale[lang][page].subTitle}
        </Grid>
      </Grid>
    </>
  );
};

export default Container;
