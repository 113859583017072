import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Button,
  FormControl,
  InputAdornment,
  TextField,
  Container,
} from "@material-ui/core"; // InputLabel, OutlinedInput,
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom"; // useLocation
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import EmailIcon from "@material-ui/icons/Email";
import MessageIcon from "@material-ui/icons/Message";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
// import host from '../../../obsolete/config';
import locale from "../../components/Reservation/Locale";

const useStyles = makeStyles(() => ({
  root: {
    border: "1px solid #cccccc",
    width: "100%",
    marginTop: 50,
    marginBottom: 50,
    paddingTop: 50,
    paddingBottom: 50,
    backgroundColor: "#f5f5f5",
  },
  form: {
    paddingBottom: 30,
  },
}));

const host = "https://www.novarentacar.gr/api";

const Contact = ({ lang }) => {
  const classes = useStyles();
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    telephone: "",
    email: "",
    comments: "",
  });
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await window.grecaptcha.execute(
      "6LeeIbQZAAAAAFOR_gvWMYTc6TaFq0p2Pr5Fo8zd",
      { action: "submit" }
    );
    const response = await fetch(`${host}/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        contact: form,
        recaptcha: token,
      }),
    });
    const res = await response.json();
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "spaEvent",
      eventCategory: "Contact",
      eventAction: "Click",
      eventLabel: "Submit",
    });
    if (res.success) history.push(`/thank_you.${lang}.htm`);
  };

  const handleChangeForm = (prop) => (event) => {
    setForm({
      ...form,
      [prop]: event.target.value,
    });
  };

  return (
    <>
      <Header lang={lang} page="contact" />

      <Container fixed maxWidth="sm" className={classes.root}>
        <Grid item xs={12}>
          <FormControl fullWidth className={classes.form} variant="outlined">
            <TextField
              required
              id="firstName"
              value={form.firstName}
              onChange={handleChangeForm("firstName")}
              label={locale[lang].step3.firstName}
              variant="filled"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountBoxIcon className={classes.icon} />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth className={classes.form} variant="outlined">
            <TextField
              required
              id="lastName"
              value={form.lastName}
              onChange={handleChangeForm("lastName")}
              label={locale[lang].step3.lastName}
              variant="filled"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountBoxIcon className={classes.icon} />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth className={classes.form} variant="outlined">
            <TextField
              required
              id="telephone"
              value={form.telephone}
              onChange={handleChangeForm("telephone")}
              label={locale[lang].step3.telephone}
              variant="filled"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIphoneIcon className={classes.icon} />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth className={classes.form} variant="outlined">
            <TextField
              required
              id="email"
              value={form.email}
              onChange={handleChangeForm("email")}
              label={locale[lang].step3.email}
              variant="filled"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon className={classes.icon} />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth className={classes.form} variant="outlined">
            <TextField
              required
              id="filled-required"
              label={locale[lang].step3.comments}
              value={form.comments}
              variant="filled"
              // placeholder="Comments"
              multiline
              rows={4}
              onChange={handleChangeForm("comments")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MessageIcon className={classes.icon} />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Button
            // variant="outlined"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={
              !form.firstName ||
              !form.lastName ||
              !form.telephone ||
              !form.email ||
              !form.comments
            }
          >
            {locale[lang].step3.submit}
          </Button>
        </Grid>
      </Container>

      <Footer lang={lang} page="contact" />
    </>
  );
};

Contact.propTypes = {
  lang: PropTypes.string,
};

Contact.defaultProps = {
  lang: "en",
};

export default Contact;
