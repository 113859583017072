const theme = {
  palette: {
    primary: {
      light: "#F26521",
      main: "#F26521",
      dark: "#F26521",
      // contrastText: '#002461',
      contrastText: "#FFFFFF",
    },
    secondary: {
      light: "#002461",
      main: "#444",
      dark: "#002461",
      contrastText: "#fff",
    },
  },
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiGrid: {
      item: {
        "& > h1": {
          fontSize: "2.5rem",
        },
        "& > h3": {
          fontSize: "1.5rem",
        },
        "& > h4": {
          fontSize: "1.2rem",
        },
      },
    },
    MuiInputBase: {
      root: {
        backgroundColor: "#FFFFFF",
        padding: 5,
      },
    },
    MuiMenuItem: {
      root: {
        "&$selected": {
          backgroundColor: "#002461",
          color: "#FFF",
          "&:hover": {
            backgroundColor: "#002461",
          },
        },
      },
    },
    MuiSlider: {
      root: {
        height: 8,
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: "#FFFFFF",
        padding: 5,
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "#FFFFFF",
        "&:$hover": {
          backgroundColor: "rgba(0,0,0,0.03)",
        },
        "&$focused": {
          backgroundColor: "rgba(255,255,255,0.9)",
        },
      },
    },
    MuiCard: {
      root: {
        height: "100%",
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: "1rem",
      },
    },
  },
};

export default theme;
