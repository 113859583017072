import en from "./en";
import gr from "./gr";
import de from "./de";
import it from "./it";
import { LocaleProp } from "../types";

const locale: LocaleProp = {
  en,
  gr,
  de,
  it,
};

export default locale;
