import React, { useState } from "react";
import { Grid, Container } from "@material-ui/core";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import locale from "./Locale/index";
import Reservation from "../../components/Reservation/Reservation";
import { ContainerProps } from "../types";
import { useStyles } from "./Styles";
import EspaModal from "../../components/Modals/Espa/Espa";

const Component = ({ lang }: ContainerProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleToggleModal = () => setOpen(!open);

  return (
    <>
      <Header lang={lang} page="index" />
      <Container fixed className={classes.root} direction="column">
        <Grid
          item
          xs={12}
          className={classes.imgHolder}
          onClick={handleToggleModal}
        >
          {lang === "gr" && (
            <img
              src="images/espa/gr/espa.png"
              alt="Espa"
              className={classes.img}
            />
          )}
          {lang !== "gr" && (
            <img
              src="images/espa/en/espa.png"
              alt="Espa"
              className={classes.img}
            />
          )}
        </Grid>
        <Reservation lang={lang} />
        <Grid item xs={12} sm={6} className={classes.text}>
          <p>{locale[lang].text1}</p>
          <p>{locale[lang].text2}</p>
          <p>{locale[lang].text3}</p>
          <p>{locale[lang].text4}</p>
          <ul>
            {locale[lang].text4opt.map((value, index) => (
              <li key={index}>{value}</li>
            ))}
          </ul>
          <p>{locale[lang].text5}</p>
          <ul>
            {locale[lang].text5opt.map((value, index) => (
              <li key={index}>{value}</li>
            ))}
          </ul>
          <p>{locale[lang].text6}</p>
        </Grid>
      </Container>
      {/* </Grid> */}

      <Footer lang={lang} page="index" />

      <EspaModal lang={lang} open={open} onClick={handleToggleModal} />
    </>
  );
};

export default Component;
