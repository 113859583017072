export default {
  offer: {
    title: "Offriamo:",
    options1: "Chilometri illimitati",
    options2: "tra cui l’ IVA di 24%",
    options3: "Assicurazione completa (KASKO TOTALE)",
    options4: "Assicurazione furto del veicolo",
    options5: "Secondo guidatore",
    options6: "24 ore di assistenza stradale in Grecia",
    options7:
      "Consegna i ritoro del veicolo, gratuitamente, all’ aeroporto di Salonicco",
  },
  insurance: {
    title: "L' assicurazione che offriamo:",
    text1:
      "Se desideri, ricevi copertura assicurativa completa (KASKO TOTALE) nonostante:",
    option1: "la categoria del veicolo",
    option2: "il periodo e la durata del noleggio",
    text2:
      "... senza alcun coinvolgimento finanzario del cliente in caso di incidente!",
  },
  equipment: {
    title: "L' equipaggiamento delle nostre automobili:",
    text: "Tutti i veicoli della nostra flotta tengono cinque porte (5D) e sono completamente attrazzati con i seguenti:",
    option1: "aria condizionata",
    option2: "servosterzo",
    option3: "alza cristalli elettrici",
    option4: "Radio-CD",
  },
  services: {
    title: "Services",
    text: "Transfer services from and to Thessaloniki with all kind of vehicles",
  },
  trademark: "NOVA RENT A CAR LLC",
};
