export default {
  offer: {
    title: "Σας προσφέρουμε:",
    options1: "Απεριόριστα χιλιόμετρα",
    options2: "ΦΠΑ 24%",
    options3: "Πλήρη ασφαλιστική κάλυψη VOLLKASKO",
    options4: "Κάλυψη κλοπής του οχήματος",
    options5: "Επιπλέον οδηγό",
    options6: "24 ώρες πανελλαδική οδική βοήθεια",
    options7:
      "Δωρεάν παράδοση και παραλαβή του μισθωμένου οχήματος στο αεροδρόμιο της Θεσσαλονίκης",
  },
  insurance: {
    title: "Η ασφάλιση που προσφέρουμε:",
    text1:
      "Σας παρέχουμε, εφόσον το επιθυμείτε, πλήρη ασφαλιστική κάλυψη (VOLLKASKO) ανεξάρτητα από:",
    option1: "την κατηγορία του αυτοκινήτου που θα επιλέξετε",
    option2: "την περίοδο και την διάρκεια της ενοικίασης",
    text2:
      "… χωρίς καμία οικονομική επιβάρυνση του πελάτη σε περίπτωση ατυχήματος!",
  },
  equipment: {
    title: "Ο εξοπλισμός των αυτοκινήτων μας:",
    text: "Όλα τα αυτοκίνητα του στόλου μας είναι πεντάθυρα (5D) και πλήρως εξοπλισμένα με τα εξής:",
    option1: "Κλιματισμό (Air-condition)",
    option2: "Υδραυλικό τιμόνι",
    option3: "Ηλεκτρικά παράθυρα",
    option4: "Ράδιο-CD",
  },
  services: {
    title: "Services",
    text: "Transfer services from and to Thessaloniki with all kind of vehicles",
  },
  trademark: "ΕΝΟΙΚΙΑΣΕΙΣ ΑΥΤΟΚΙΝΗΤΩΝ ΝΟΒΑ ΕΤΑΙΡΙΑ ΠΕΡΙΟΡΙΣΜΕΝΗΣ ΕΥΘΥΝΗΣ",
};
