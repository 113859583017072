import React from "react";
import { Grid, Container, Modal } from "@material-ui/core";
import { ModalProps } from "./types";
import { useStyles } from "./styles";
import locale from "./locale";

const Component = ({ lang, open, onClick }: ModalProps) => {
  const classes = useStyles();
  const langFolder = lang === "gr" ? "gr" : "en";
  return (
    <Modal
      open={open}
      onClose={onClick}
      aria-labelledby="espa"
      aria-describedby="espa-description"
      onClick={onClick}
    >
      <Container className={classes.modal}>
        <Grid container justify="flex-end" className={classes.header}>
          <img src={`images/espa/${langFolder}/espa.png`} alt="Espa" />
        </Grid>
        <Grid container justify="center" className={classes.diexodos}>
          <img src="images/espa/diexodos.png" alt="Diexodos" />
        </Grid>
        <Grid container className={classes.covid}>
          <Grid item xs={1}>
            <img src="images/espa/covid.png" alt="Covid" />
          </Grid>
          <Grid item xs={11}>
            {locale[langFolder].text1}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div role="button" tabIndex={0} onClick={onClick} onKeyDown={onClick}>
            <p>{locale[langFolder].text2}</p>
            <p>{locale[langFolder].text3}</p>
            <p>{locale[langFolder].text4}</p>
          </div>
        </Grid>
        <Grid container justify="center" className={classes.footer}>
          <img
            src={`images/espa/${langFolder}/macedonia.png`}
            alt="Macedonia"
          />
        </Grid>
      </Container>
    </Modal>
  );
};

export default Component;
