const style = {
  container: {
    padding: 50,
  },
  th: {
    fontWeight: 600,
  },
  row: {
    paddingTop: 15,
    paddingBottom: 15,
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
  col1: {
    fontWeight: 600,
    paddingRight: 30,
  },
  col2: {},
};

export default style;
