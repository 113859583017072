import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: 0, // theme.spacing(1),
    minWidth: 50,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  menuDesktop: {
    backgroundColor: "#444",
    color: "#FFF",
    display: "flex",
  },
  menuMobile: {
    backgroundColor: "#444",
    color: "#FFF",
    display: "none",
  },
  menuMobilePopup: {
    backgroundColor: "#444",
    color: "#FFF",
  },
  jumbotron: {
    height: 250,
    color: "#fff",
    backgroundImage: "url(images/white_tower3.png)",
    backgroundColor: "#002461",
    padding: 50,
  },
  title: {
    fontSize: 63,
    fontWeight: 600,
    fontFamily: "'Lora', serif",
  },
  subTitle: {
    fontSize: 21,
  },
  [theme.breakpoints.down("md")]: {
    menuDesktop: {
      display: "none",
    },
    menuMobile: {
      backgroundColor: "#444",
      color: "#FFF",
      display: "flex",
    },
    title: {
      fontSize: "2.2rem",
      paddingBottom: 20,
    },
    subTitle: {
      fontSize: "1.2rem",
    },
  },
}));

const MenuButton = withStyles({
  root: {
    color: "#FFF",
    "&:$hover": {
      backgroundColor: "#002461",
      boxShadow: "none",
    },
    "&.active": {
      boxShadow: "none",
      backgroundColor: "#002461",
    },
  },
})(Button);

const MenuButtonMobile = withStyles({
  root: {
    backgroundColor: "#444",
    color: "#FFF",
    width: "100%",
    borderBottom: "1px solid rgba(255,255,255,0.1)",
  },
})(Button);

export { useStyles, MenuButton, MenuButtonMobile };
