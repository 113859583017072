// import React from 'react';
import React from "react";
import { Grid } from "@material-ui/core";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"; // HashRouter BrowserRouter
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";
import Theme from "./Theme";
import Home from "../containers/Home/Home";
import Fleet from "../containers/Fleet/Fleet";
import About from "../containers/About/About";
import Terms from "../containers/Terms/Terms";
import Contact from "../containers/Contact/Contact";
import AfterSubmit from "../containers/AfterSubmit/AfterSubmit";
// import host from './config';

const useStyles = makeStyles((theme) => ({
  phone: {
    fontSize: "28px",
    padding: "30px 40px 0 0",
    color: "#666",
    fontWeight: 700,
    textAlign: "right",
    paddingRight: 0,
  },
  [theme.breakpoints.down("md")]: {
    phone: {
      fontSize: "1.2rem",
    },
  },
}));

const App = () => {
  const mytheme = createMuiTheme(Theme);
  const classes = useStyles();
  const langN = navigator.language.split("-")[0];
  let lang = langN !== null ? langN : "en";
  if (lang === "el") lang = "gr";

  return (
    <ThemeProvider theme={mytheme}>
      {/* <Router basename="/php/v4/"> */}
      <Grid container direction="row">
        <Grid item xs={6}>
          <img src="images/logo4.png" alt="logo" />
        </Grid>
        <Grid item xs={6} className={classes.phone}>
          +30 2310 488 680
        </Grid>
      </Grid>
      {/* <Router basename={(process.env.MODE === 'development') ? '/' : '/php/v4/'}> */}
      {/* <Router basename={(process.env.MODE === 'development') ? '/' : '/~buyetfmy/'}> */}
      <Router basename="/">
        <Switch>
          <Route exact path="/index.en.htm" render={() => <Home lang="en" />} />
          <Route exact path="/index.gr.htm" render={() => <Home lang="gr" />} />
          <Route exact path="/index.de.htm" render={() => <Home lang="de" />} />
          <Route exact path="/index.it.htm" render={() => <Home lang="it" />} />

          <Route
            exact
            path="/fleet.en.htm"
            render={() => <Fleet lang="en" />}
          />
          <Route
            exact
            path="/fleet.gr.htm"
            render={() => <Fleet lang="gr" />}
          />
          <Route
            exact
            path="/fleet.de.htm"
            render={() => <Fleet lang="de" />}
          />
          <Route
            exact
            path="/fleet.it.htm"
            render={() => <Fleet lang="it" />}
          />

          <Route
            exact
            path="/about_us.en.htm"
            render={() => <About lang="en" />}
          />
          <Route
            exact
            path="/about_us.gr.htm"
            render={() => <About lang="gr" />}
          />
          <Route
            exact
            path="/about_us.de.htm"
            render={() => <About lang="de" />}
          />
          <Route
            exact
            path="/about_us.it.htm"
            render={() => <About lang="it" />}
          />

          <Route
            exact
            path="/terms_and_conditions.en.htm"
            render={() => <Terms lang="en" />}
          />
          <Route
            exact
            path="/terms_and_conditions.gr.htm"
            render={() => <Terms lang="gr" />}
          />
          <Route
            exact
            path="/terms_and_conditions.de.htm"
            render={() => <Terms lang="de" />}
          />
          <Route
            exact
            path="/terms_and_conditions.it.htm"
            render={() => <Terms lang="it" />}
          />

          <Route
            exact
            path="/contact.en.htm"
            render={() => <Contact lang="en" />}
          />
          <Route
            exact
            path="/contact.gr.htm"
            render={() => <Contact lang="gr" />}
          />
          <Route
            exact
            path="/contact.de.htm"
            render={() => <Contact lang="de" />}
          />
          <Route
            exact
            path="/contact.it.htm"
            render={() => <Contact lang="it" />}
          />

          <Route
            exact
            path="/thank_you.en.htm"
            render={() => <AfterSubmit lang="en" type="thankyou" />}
          />
          <Route
            exact
            path="/thank_you.gr.htm"
            render={() => <AfterSubmit lang="gr" type="thankyou" />}
          />
          <Route
            exact
            path="/thank_you.de.htm"
            render={() => <AfterSubmit lang="de" type="thankyou" />}
          />
          <Route
            exact
            path="/thank_you.it.htm"
            render={() => <AfterSubmit lang="it" type="thankyou" />}
          />

          {/* <Route exact path="/about.**.htm" component={About} /> */}
          {/* <Route exact path="/" component={Home} /> */}
          <Route render={() => <Home lang={lang} />} />
          {/* <Route component={Home} /> */}
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;
