const locale = {
  en: {
    text1:
      "SUPPORT MICRO AND SMALL ENTERPISES AFFECTED BY COVID-19 IN CENTRAL MAKEDONIA",
    text2:
      "Τhe enterprise NOVA RENT A CAR LTD with headquarters in the Region of Central Macedonia, was co-financed by the European Regional Development Fund (ERDF) of the European Union (ΕΕ) for the implementation of its project under the Action “Support micro and small enterprises affected by Covid-19 in Central Macedonia“ under the framework of the Operational Program «Central Macedonia» 2014-2020.",
    text3:
      "The Action is aimed at empowering micro and small enterprises in the Region of Central Macedonia in the form of a non-repayable grant to ensure sufficient liquidity to deal with the effects of the Covid-19 pandemic.",
    text4:
      "Ιnvestment’s total budget is 50000€ (100% public expenditure). The Action is co-financed by Greece and the European Union - European Regional Development Fund.",
  },
  gr: {
    text1:
      "ENIΣΧΥΣΗ ΜΙΚΡΩΝ ΚΑΙ ΠΟΛΥ ΜΙΚΡΩΝ ΕΠΙΧΕΙΡΗΣΕΩΝ ΠΟΥ ΕΠΛΗΓΗΣΑΝ ΑΠΟ ΤΟΝ COVID-19 ΣΤΗΝ ΚΕΝΤΡΙΚΗ ΜΑΚΕΔΟΝΙΑ",
    text2:
      "Η επιχείρηση ENOIKIAΣΕΙΣ ΑΥΤΟΚΙΝΗΤΩΝ ΝΟΒΑ ΕΠΕ που εδρεύει στην Περιφέρεια Κεντρικής Μακεδονίας, εντάχθηκε στη Δράση «Ενίσχυση μικρών και πολύ μικρών επιχειρήσεων που επλήγησαν από τον Covid-19 στην Κεντρική Μακεδονία» συνολικού προϋπολογισμού 200 εκ. ευρώ.",
    text3:
      "Η Δράση στοχεύει στην παροχή ενίσχυσης των πολύ μικρών και μικρών επιχειρήσεων της Περιφέρειας Κεντρικής Μακεδονίας με τη μορφή της μη επιστρεπτέας επιχορήγησης για τη διασφάλιση επαρκούς ρευστότητας για την αντιμετώπιση των επιπτώσεων της πανδημίας Covid-19.",
    text4:
      "Ο συνολικός προϋπολογισμός (100% Δημόσια Δαπάνη) της επένδυσης είναι 50000€, και συγχρηματοδοτείται από το Ευρωπαϊκό Ταμείο Περιφερειακής Ανάπτυξης (ΕΤΠΑ) της Ευρωπαϊκής Ένωσης (ΕΕ) και Εθνικούς Πόρους στο πλαίσιο του Επιχειρησιακού Προγράμματος «Κεντρική Μακεδονία» 2014-2020.",
  },
};

export default locale;
