import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
  },
  text: {
    padding: 30,
  },
  [theme.breakpoints.down("md")]: {
    root: {
      padding: 0,
    },
  },
  media: {
    height: 140,
  },
  img: {
    padding: "30px 0",
    width: "100%",
    maxWidth: 400,
    height: "auto",
  },
  imgHolder: {
    display: "flex",
    justifyContent: "flex-end",
  },
  modal: {
    position: "absolute",
    maxWidth: 600,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[1],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
    [theme.breakpoints.down("md")]: {
      width: "70%",
      fontSize: "0.8rem",
    },
  },
}));

export { useStyles };
