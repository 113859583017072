const locale = {
  en: [
    ["DRIVER’S AGE:", "Driver should be at least 23 years old."],
    [
      "DRIVER’S LICENCE:",
      "A valid driving license held for at least one year is required.",
    ],
    [
      "FINES:",
      "Tickets and fines resulting from any violation of the Greek traffic law during rental period are borne solely by the renter.",
    ],
    [
      "INSURANCE:",
      "All authorised drivers are covered against fire and liability to third parties for death, injury or damage to property.",
    ],
    [
      "COLLISION DAMAGE WAIVER (CDW) - €600.00 deductible::",
      "Renter's maximal liability in case of an accident is reduced to €600.00. It is included on all price lists.",
    ],
    [
      "COLLISION DAMAGE WAIVER (CDW) - €200.00 deductible::",
      "Renter's maximal liability in case of an accident is reduced to €200.00. It Is optional with extra daily charge.",
    ],
    [
      "FULL DAMAGE WAIVER (FDW):",
      "FDW-€0.00 deductible: Renter's maximal liability in case of an accident is reduced to €0. Full damage waiver insurance is optional.",
    ],
    [
      "THEFT PROTECTION:",
      "Theft protection insurance is included on all price lists.",
    ],
    [
      "PERSONAL ACCIDENT INSURANCE:",
      "Personal accident insurance is included on all price lists. It covers the driver for loss of life or total permanent disablement for the amount of €15,000.00 and covering the passengers for injuries up to €500,000.00",
    ],
    ["TAXES:", "All prices include 24% VAT."],
    [
      "PICK-UPS AND DELIVERIES:",
      "All vehicles are picked-up/delivered according to renter's wish at Macedonia International Airport of Thessaloniki without charge. Charges are applicable on any other location. For pick-ups and deliveries outside working hours the renter will be charged €20.00 per case (working hours are from 08:00 to 21:00).",
    ],
    ["GASOLINE:", "Gasoline consumption is payable by the renter."],
    [
      "BABY SEATS:",
      "Available on request, free of charge (maximum one baby seat per vehicle).",
    ],
    [
      "VEHICLE AVAILABILITY:",
      "Vehicle models are according to original planning. Delivery problems, however, may compel us to supply another vehicle in exceptional cases.",
    ],
    [
      "ACROSS THE BORDER RENTALS:",
      "It is strictly prohibited to exit the Greek borders.",
    ],
    [
      "FERRYING THE CAR:",
      "A written authorisation from NOVA is required in advance.",
    ],
  ],
  gr: [
    ["ΗΛΙΚΙΑ ΟΔΗΓΟΥ:", "Ελάχιστο όριο ηλικίας είναι τα 23 έτη."],
    [
      "ΑΔΕΙΑ ΟΔΗΓΗΣΗΣ:",
      "O οδηγός πρέπει να έχει λάβει την άδεια οδήγησης τουλάχιστον έναν χρόνο νωρίτερα.",
    ],
    [
      "ΠΡΟΣΤΙΜΑ:",
      "Όλα τα πρόστιμα και οι διοικητικές ποινές επιβαρύνουν τον ενοικιαστή.",
    ],
    [
      "ΑΣΦΑΛΕΙΑ:",
      "Οι ενοικιαστές των αυτοκινήτων της NOVA είναι ασφαλισμένοι έναντι πυρκαγιάς και έναντι τρίτων για θάνατο και σωματικές βλάβες.",
    ],
    [
      "ΑΠΑΛΛΑΓΗ ΕΥΘΥΝΗΣ - Μεικτή ασφάλεια με απαλλαγή €600,00",
      "Σε περίπτωση ατυχήματος με υπαιτιότητα του πελάτη, ο πελάτης είναι υποχρεωμένος να καταβάλει το ποσό των €600,00 και απαλλάσσεται τις υπόλοιπης ευθύνης πληρωμής. Η μεικτή ασφάλεια συμπεριλαμβάνεται σε όλους τους τιμοκαταλόγους χωρίς χρέωση του πελάτη.",
    ],
    [
      "ΑΠΑΛΛΑΓΗ ΕΥΘΥΝΗΣ - Μεικτή ασφάλεια με απαλλαγή €200,00",
      "Σε περίπτωση ατυχήματος με υπαιτιότητα του πελάτη, ο πελάτης είναι υποχρεωμένος να καταβάλει το ποσό των €200,00 και απαλλάσσεται τις υπόλοιπης ευθύνης πληρωμής. Η μεικτή ασφάλεια με απαλλαγή €200,00 είναι προαιρετική και παρέχεται με επιπλέον ημερήσια χρέωση.",
    ],
    [
      "Πλήρης ασφάλεια:",
      "Ο ενοικιαστής απαλλάσσεται πλήρως της ευθύνης για ζημιές που θα προκληθούν στο μισθωμένο όχημα κατά την διάρκεια της ενοικίασης. Αυτή η ασφαλιστική κάλυψη είναι προαιρετική με πρόσθετη χρέωση.",
    ],
    [
      "ΚΑΛΥΨΗ ΚΛΟΠΗΣ:",
      "Ο ενοικιαστής απαλλάσσεται της ευθύνης για κλοπή αυτοκινήτου. Αυτή η κάλυψη συμπεριλαμβάνεται στο ασφαλιστικό πακέτο που παρέχει η NOVA σε όλους τους πελάτες που κάνουν την κράτησή τους μέσω Internet.",
    ],
    [
      "ΑΣΦΑΛΙΣΗ ΟΔΗΓΟΥ ΚΑΙ ΕΠΙΒΑΤΩΝ:",
      "Προσφέρεται δωρεάν και καλύπτει αποζημίωση οδηγού €15,000 για θάνατο ή ολική ανικανότητα. Αποζημίωση επιβαινόντων για σωματικές βλάβες έως €500.000,00.",
    ],
    [
      "ΦΟΡΟΙ:",
      "Ο δημοσιευμένος τιμοκατάλογος στην ιστοσελίδα της εταιρείας μας περιλαμβάνει Φ.Π.Α 24%.",
    ],
    [
      "ΠΑΡΑΛΑΒΕΣ-ΠΑΡΑΔΟΣΕΙΣ:",
      "Τα αυτοκίνητα παραδίδονται - παραλαμβάνονται σύμφωνα με την επιθυμία του πελάτη στο αεροδρόμιο της Θεσσαλονίκης χωρίς χρέωση. Για οποιοδήποτε άλλο σημείο ισχύουν χρεώσεις κατόπιν συμφωνίας. Για παραδόσεις-παραλαβές μετά τις ώρες λειτουργίας της NOVA η χρέωση ανέρχεται στα €20,00 (ώρες λειτουργίας 08:00 με 21:00).",
    ],
    ["ΒΕΝΖΙΝΗ:", "Πληρώνεται από τον μισθωτή."],
    [
      "ΠΑΙΔΙΚΟ ΚΑΘΙΣΜΑ:",
      "Διατίθεται δωρεάν μόνο μετά από κράτηση (ο ενοικιαστής δικαιούται μόνο ένα παιδικό κάθισμα ανά κράτηση).",
    ],
    [
      "ΑΛΛΑΓΗ ΤΥΠΟΥ ΑΥΤΟΚΙΝΗΤΟΥ:",
      "Η εταιρία διατηρεί το δικαίωμα να αλλάξει τον τύπο του αυτοκινήτου σε ειδικές περιπτώσεις.",
    ],
    [
      "ΕΚΜΙΣΘΩΣΗ ΕΚΤΟΣ ΣΥΝΟΡΩΝ:",
      "Απαγορεύεται ρητά η έξοδος των αυτοκινήτων της NOVA εκτός των ελληνικών συνόρων.",
    ],
    [
      "ΜΕΤΑΦΟΡΑ ΜΕ ΠΛΟΙΟ:",
      "Απαγορεύεται η φόρτωση των αυτοκινήτων σε πλοίο χωρίς την γραπτή συγκατάθεση της NOVA.",
    ],
  ],
  de: [
    ["ALTER:", "Der Fahrer muss ein Mindestalter von 23 Jahren haben."],
    ["FAHRERERLAUBNIS:", "Gültiger Führerschein seit mindestens einem Jahr."],
    [
      "GELDSTRAFEN:",
      "Geldstrafen und andere Sanktionen, die aus Verstößen gegen die griechische Straßenverkehrsordnung resultieren, sind für die Dauer der Anmietung vom Mieter zu tragen.",
    ],
    [
      "VERSICHERUNG:",
      "Alle berechtigten Fahrer unserer Fahrzeuge sind gegen Dritte bei Todesfall, Verletzung oder Sachschäden versichert.",
    ],
    [
      "VOLLKASKO-VERSICHERUNG - Vollkasko mit reduzierter Haftung (€600,00)",
      "Die maximale Haftung des Mieters im Fall eines Unfalls durch Verschulden des Mieters ist auf €600,00 reduziert worden und ist im Gesamtpreis inbegriffen.",
    ],
    [
      "VOLLKASKO-VERSICHERUNG - Vollkasko mit reduzierter Haftung (€200,00)",
      "Die maximale Haftung des Mieters im Fall eines Unfalls durch Verschulden des Mieters ist auf €200,00 reduziert worden und ist im Gesamtpreis inbegriffen. Es ist optional mit Aufpreis pro Tag.",
    ],
    [
      "DIEBSTAHLVERSICHERUNG:",
      "Der Mieter wird von der Haftung über Autodiebstahl befreit. Diese Versicherung ist im Versicherungspaket für alle NOVA-Kunden, die ihre Buchung über Internet machen, inbegriffen.",
    ],
    [
      "PERSÖNLICHE UNFALLVERSICHERUNG:",
      "Der Fahrer ist gegen Tod oder Folgen einer andauernden Körperbehinderung bis zu €15.000,00 versichert. Zusätzlich sind die Mitfahrer bei Körperverletzung bis zu €500.000,00sichert.",
    ],
    ["STEUERN:", "Inkl. MwSt. in Höhe von 24%"],
    [
      "ANMIETUNG/ RÜCKGABE:",
      "Die Fahrzeuge werden - entsprechend den Wünschen des Kunden - am Flughafen von Thessaloniki. Rückgabe außerhalb der Geschäftszeiten (von 08:00 bis 21:00) wird ein Zuschlag in Höhe von €20 berechnet.",
    ],
    ["BENZIN:", "Der Benzinverbrauch wird vom Mieter getragen."],
    [
      "KINDERSITZ:",
      "Auf Anfrage erhältlich. Kostenlos, maximal 1 Kindersitz pro Fahrzeug.",
    ],
    [
      "FAHRZEUGKATEGORIE ÄNDERN",
      "Das Unternehmen behält sich das Recht vor, die Fahrzeugkategorie in Ausnahmefällen zu ändern.",
    ],
    [
      "FAHRTEN AUSSERHALB GRIECHENLANDS:",
      "Fahrten aus Griechenland ins Ausland sind nicht erlaubt.",
    ],
    [
      "FÄHRÜBERFAHRT:",
      "Nur mit schriftlicher Genehmigung von Nova (unbedingt vorher beantragen).",
    ],
  ],
  it: [
    ["Età del guidatore:", "L' età minima è fissata a 23 anni."],
    [
      "Patente di guida:",
      "Il guidatore deve avere una patente di guida valida compresa tra almeno un anno.",
    ],
    [
      "Multe:",
      "Biglietti e successive sanzioni amministrative sono a spese degli affittuari.",
    ],
    [
      "Assicurazione:",
      "Gli affituari dei veicoli di NOVA sono assicurati contro incendi e contro terzi per morte e lesioni personali.",
    ],
    [
      "Scarico di responsabilità - Assicurazione Collision Damage Waiver (CDW) con un importo deducibile €600,00:",
      "In caso di incidente per colpa del cliente, il cliente è obbligato a pagare €600,00 ed è esente dal pagamento del debito residuo. L’ assicurazione CDW è inclusa in tutte le tariffe senza spese del cliente.",
    ],
    [
      "Scarico di responsabilità - Assicurazione Collision Damage Waiver (CDW) con un importo deducibile €200,00:",
      "In caso di incidente per colpa del cliente, il cliente è obbligato a pagare €200,00 ed è esente dal pagamento del debito residuo. È opzionale con supplemento giornaliero.",
    ],
    [
      "Assicurazione Full Damage Waiver (FDW): ",
      "Il affituario è completamente esente dalla responsabilità per i danni causati al veicolo noleggiato durante il noleggio.",
    ],
    [
      "Copertura furto:",
      "L'affittuario è liberato dalla responsabilità per furto del veicolo. Questa copertura è inclusa nel pacchetto assicurativo che NOVA offre a tutti i clienti che fanno una prenotazione online.",
    ],
    [
      "Assicurazione del guidatore e dei passeggeri:",
      "È gratuita e copre un risarcimento del guidatore per morte o invalidità totale di €15.000. Risarcimento dei passeggeri per ferite fino a €500.000.",
    ],
    [
      "Tasse:",
      "Il listino prezzi pubblicato sul sito della nostra azienda comprende l’ IVA di 24%.",
    ],
    [
      "Ritiro e consegna:",
      "I veicoli sono ritirati – consegnati, secondo i desideri del cliente, all’ aeroporto di Salonicco gratuitamente. Per ritiro/consegna dopo l’orario di lavoro di NOVA è previsto un costo aggiuntivo di €20,00 (ore di lavoro sono dalle 08:00 alle 21:00).",
    ],
    [
      "Benzina:",
      "Affittuario paga per la benzina consumata durante il noleggio.",
    ],
    [
      "Seggiolino per bambini:",
      "È disponibile su prenotazione, gratuitamente, un seggiolino per bambini (solo un seggiolino per prenotazione).",
    ],
    [
      "Cambio del tipo della auto:",
      "L' azienda ha il diritto di cambiare il tipo di autovettura in casi particolari.",
    ],
    [
      "Viaggiare fuori dai confini nazionali:",
      "È espressamente vietato viaggiare con veicoli di NOVA al di fuori dei confini greci.",
    ],
    [
      "Trasporto via mare:",
      "È vietato il caricamento del veicolo su una nave senze il consenso scritto di NOVA.",
    ],
  ],
};

export default locale;
