export default {
  step1: {
    title: "FARE UNA PRENOTAZIONE",
    text1: "Fai la tua prenotazione oggi!",
    text2: "Ritiro da:",
    text2opt: ["AEROPORTO DI SALONICCO", "PREVIO ACCORDO"],
    text3: "Data di ritiro:",
    text4:
      "Nota: Per un ritiro prima delle 8:00 e dopo le 21:00 è previsto un costo aggiuntivo di €20,00",
    text5: "Consegna a:",
    text6: "Data di consegna:",
    text7:
      "Nota: Per una consegna prima delle 8:00 e dopo le 21:00 è previsto un costo aggiuntivo di €20,00",
  },
  step2: {
    title: "SCEGLIERE L´AUTO",
    category: "Categoria",
    insurance: "Full Insurance Coverage (VOLLKASKO)",
    insurance1a:
      "Assicurazione Collision Damage Waiver (CDW) con un importo deducibile €600.",
    insurance1b: "Uso di carta di credito o garanzia di €600",
    insurance2a:
      "Assicurazione Collision Damage Waiver (CDW) con un importo deducibile €200.",
    insurance2b: "Uso di carta di credito o garanzia di €200",
    insurance3a:
      "Il affituario è completamente esente dalla responsabilità per i danni causati al veicolo noleggiato durante il noleggio.",
    insurance3b: "*NESSUNA CARTA DI CREDITO O GARANZIA",
    babyseat: "BABY SEAT",
    gps: "GPS (Extra €25.00)",
  },
  step3: {
    title: "CONFIRMARE E PRENOTARE",
    firstName: "NOME",
    lastName: "COGNOME",
    flight: "NUMERO DI VOLO",
    email: "EMAIL",
    telephone: "TELEFONO",
    comments: "COMMENTI",
    submit: "INVIARE",
  },
  reservation: {
    title: "LA TUA PRENOTAZIONE", // panel_header
    total: "Totale",
    extra: "Extra",
    days: "Giorni",
  },
  nav: {
    previous: "PRECEDENTE",
    next: "SEGUENTE",
  },
};
