import en from "./En";
import gr from "./Gr";
import de from "./De";
import it from "./It";

export default {
  en,
  gr,
  de,
  it,
};
