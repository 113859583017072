export default {
  text1:
    "Are you traveling for professional reasons or leisure? Do you need to rent an economic and reliable car from a consistent and experienced rent a car company?",
  text2:
    "Do you wish to be transported (you or your group of persons) with safety and discretion?",
  text3: "Nova rent a car Thessaloniki offers you the choice you seek.",
  text4: "Delivery and collection of the cars at:",
  text4opt: [
    "Makedonia international Airport of Thessaloniki,",
    "Thessaloniki city centre,",
    "Hotels,",
    "Bus terminal station (KTEL),",
    "Train terminal station (OSE),",
    "Wherever you wish.",
  ],
  text5:
    "Experienced and specialized staff, new and well maintained cars ensure the provision of:",
  text5opt: [
    "High quality rent a car services,",
    "Personal transfer services from and to Thessaloniki and Makedonia airport,",
    "Group transfer services with minibuses (7,9 or more seats),",
    "VIP services with or without drivers of luxury cars.",
  ],
  text6:
    "Excellent prices/rates, safety and discretion. Friendly, polite staff in constant communication with you, ready to serve you and assist you on any need may arise.",
  modal1:
    "Τhe enterprise NOVA RENT A CAR LTD with headquarters in the Region of Central Macedonia, was co-financed by the European Regional Development Fund (ERDF) of the European Union (ΕΕ) for the implementation of its project under the Action “Support micro and small enterprises affected by Covid-19 in Central Macedonia“ under the framework of the Operational Program «Central Macedonia» 2014-2020.",
  modal2:
    "The Action is aimed at empowering micro and small enterprises in the Region of Central Macedonia in the form of a non-repayable grant to ensure sufficient liquidity to deal with the effects of the Covid-19 pandemic.",
  modal3:
    "Ιnvestment’s total budget is 50000€ (100% public expenditure). The Action is co-financed by Greece and the European Union - European Regional Development Fund.",
};
