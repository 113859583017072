const locale = {
  en: {
    title: "Our fleet",
    category: "Category",
  },
  gr: {
    title: "Ο στόλος μας",
    category: "Κατηγορία",
  },
  de: {
    title: "Autos",
    category: "Kategorie",
  },
  it: {
    title: "La nuestra flotta",
    category: "Categoria",
  },
};

export default locale;
