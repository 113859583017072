export default {
  index: {
    menu: "ΑΡΧΙΚΗ",
    title: "Kαλώς ήλθατε στη Nova Rent a Car",
    subTitle: "Ενοικιάσεις αυτοκινήτων στη Θεσσαλονίκη",
  },
  fleet: {
    menu: "ΣΤΟΛΟΣ",
    title: "Στόλος Αυτοκινήτων",
    subTitle: "",
  },
  about_us: {
    menu: "Η ΕΤΑΙΡΙΑ",
    title: "Η Nova Rent a Car",
    subTitle: "",
  },
  terms_and_conditions: {
    menu: "ΟΡΟΙ & ΠΡΟΥΠΟΘΕΣΕΙΣ",
    title: "Γενικοί όροι ενοικιάσεως",
    subTitle: "",
  },
  contact: {
    menu: "ΕΠΙΚΟΙΝΩΝΙΑ",
    title: "Επικοινωνήστε μαζί μας",
    subTitle:
      "Εάν επιθυμείτε περισσότερες πληροφορίες σχετικά με μία κράτηση, εξοπλισμό κτλ, μην διστάζετε να επικοινωνήσετε μαζί μας. Θα χαρούμε να σας εξυπηρετήσουμε.",
  },
};
