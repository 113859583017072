import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Stepper,
  Step,
  StepLabel,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Card,
  CardActionArea,
  CardMedia,
  InputAdornment,
  TextField,
  FormGroup,
  Checkbox,
} from "@material-ui/core"; // InputLabel, OutlinedInput,
import { useHistory } from "react-router-dom"; // useLocation
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import FlightIcon from "@material-ui/icons/Flight";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import EmailIcon from "@material-ui/icons/Email";
import MessageIcon from "@material-ui/icons/Message";
import "date-fns";
import enLocale from "date-fns/locale/en-US";
import deLocale from "date-fns/locale/de";
import elLocale from "date-fns/locale/el";
import itLocale from "date-fns/locale/it";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { NavigateNext, NavigateBefore } from "@material-ui/icons";
import locale from "./Locale";
import useStyles from "./Styles";
import Radio from "./Radio";
// import fleetCategory from "../Data/fleetCategory";
import { ContainerProps } from "../../containers/types";
import { StateProp } from "./types";

declare global {
  interface Window {
    grecaptcha: any; // eslint-disable-line
    dataLayer: any; // eslint-disable-line
  }
}

const localeMap = {
  en: enLocale,
  gr: elLocale,
  de: deLocale,
  it: itLocale,
};
const localeStringMap = {
  en: "en-US",
  gr: "el",
  de: "de-DE",
  it: "it-IT",
};
const localOptions = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
};

const host = "https://www.novarentacar.gr/api";

// Bluehost has cache and we need to bust it every time
const v = "3.31";
console.log(v); // eslint-disable-line

const Component = ({ lang }: ContainerProps) => {
  const classes = useStyles();
  const [step, setStep] = useState(0);
  const [total, setTotal] = useState(0);
  const [fleetCategory, setFleetCategory] = useState([]);
  const pickupDate = new Date();
  pickupDate.setDate(pickupDate.getDate() + 1);
  const deliveryDate = new Date();
  // deliveryDate.setDate(pickupDate.getDate() + 3);
  deliveryDate.setTime(pickupDate.getTime() + 3 * 24 * 60 * 60 * 1000);
  const history = useHistory();

  const [state, setState] = useState({
    pickupLocation: 0,
    pickupHour: "09",
    pickupMinute: "00",
    pickupMonth:
      pickupDate.getMonth() + 1 < 10
        ? `0${pickupDate.getMonth() + 1}`
        : pickupDate.getMonth() + 1,
    pickupDay:
      pickupDate.getDate() < 10
        ? `0${pickupDate.getDate()}`
        : pickupDate.getDate(),
    pickupYear: pickupDate.getFullYear(),
    selectedPickupDate: new Date(
      pickupDate.getFullYear(),
      pickupDate.getMonth(),
      pickupDate.getDate()
    ),
    deliveryLocation: 0,
    deliveryHour: "09",
    deliveryMinute: "00",
    deliveryMonth:
      deliveryDate.getMonth() + 1 < 10
        ? `0${deliveryDate.getMonth() + 1}`
        : deliveryDate.getMonth() + 1,
    deliveryDay:
      deliveryDate.getDate() < 10
        ? `0${deliveryDate.getDate()}`
        : deliveryDate.getDate(),
    deliveryYear: deliveryDate.getFullYear(),
    selectedDeliveryDate: new Date(
      deliveryDate.getFullYear(),
      deliveryDate.getMonth(),
      deliveryDate.getDate()
    ),
    category: "1",
    categoryTitle: fleetCategory.length ? fleetCategory[0].title : "",
    extraDay: 0,
    extra: 0,
    days: 3,
    insurance: 0,
    babyseat: 0,
    gps: 0,
  });

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    flight: "",
    telephone: "",
    email: "",
    comments: "",
  });

  const identity = () =>
    Date.now().toString(16) + Math.random().toString(16) + "0".repeat(16);

  const getFleetCategory = async () => {
    const response = await fetch(`${host}/`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        p: "fleetCategory",
      }), // body data type must match "Content-Type" header
    });
    const result = await response.json();
    setFleetCategory(result.data);
  };

  const getRates = async () => {
    const url = `${host}/index.php?p=rates&category=${state.category}&from=${
      state.pickupMonth
    }-${state.pickupDay}&to=${state.deliveryMonth}-${
      state.deliveryDay
    }&extraday=${state.extraDay}&v=${identity()}`;
    const response = await fetch(url);
    const data = await response.json();
    const dataTotal = data.data.reduce(
      (accumulator: number, currentValue: number) =>
        accumulator + Number(currentValue.price),
      0
    );
    setTotal(dataTotal + state.extra);
    return true;
  };

  const calculateExtras = (_state: StateProp): Promise<number> =>
    new Promise((resolve) => {
      const { pickupHour, deliveryHour, gps, insurance, days } = _state;
      let extra = 0;
      if (Number(pickupHour) < 8 || Number(pickupHour) >= 21) extra += 20;
      if (Number(deliveryHour) < 8 || Number(deliveryHour) >= 21) extra += 20;
      if (gps) extra += 25;
      if (insurance === 1) extra += days * 3;
      if (insurance === 2) extra += days * 10;
      resolve(extra);
    });

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const _state = {
      ...state,
      [event.target.name]: event.target.value,
    };
    if (event.target.name === "gps") _state.gps = state.gps ? 0 : 1;
    if (event.target.name === "insurance")
      _state.insurance = Number(event.target.value);
    if (event.target.name === "babyseat")
      _state.babyseat = state.babyseat ? 0 : 1;
    if (event.target.name === "category") {
      _state.categoryTitle =
        fleetCategory[Number(event.target.value) - 1].title;
    }
    _state.extra = await calculateExtras(_state);
    setState(_state);
  };

  const handleChangeForm =
    (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setForm({
        ...form,
        [prop]: event.target.value,
      });
    };

  const handleChangePickup = async (date: Date) => {
    // Min Delivery date must be +3 days from pickup date
    const minDateTo = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    minDateTo.setDate(date.getDate() + 3);
    const minDateToDate = new Date(
      minDateTo.getFullYear(),
      minDateTo.getMonth(),
      minDateTo.getDate()
    );
    const _state = {
      ...state,
      pickupMonth:
        date.getMonth() + 1 < 10
          ? `0${date.getMonth() + 1}`
          : date.getMonth() + 1,
      pickupDay: date.getDate() < 10 ? `0${date.getDate()}` : date.getDate(),
      pickupYear: date.getFullYear(),
      selectedPickupDate: date,
    };
    // Set the Delivery date if its less than 3 days
    if (state.selectedDeliveryDate < minDateToDate) {
      _state.deliveryMonth =
        minDateToDate.getMonth() + 1 < 10
          ? `0${minDateToDate.getMonth() + 1}`
          : minDateToDate.getMonth() + 1;
      _state.deliveryDay =
        minDateToDate.getDate() < 10
          ? `0${minDateToDate.getDate()}`
          : minDateToDate.getDate();
      _state.selectedDeliveryDate = minDateToDate;
    }
    // Set the total days
    const differenceInTime =
      _state.selectedDeliveryDate.getTime() -
      _state.selectedPickupDate.getTime();
    const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
    _state.days = differenceInDays;
    _state.extra = await calculateExtras(_state);
    setState(_state);
  };

  const handleChangeDelivery = async (date: Date) => {
    // Min Pickup date must be -3 days from Delivery date
    const minPickupDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    minPickupDate.setDate(date.getDate() - 3);
    const minPickupDateDate = new Date(
      minPickupDate.getFullYear(),
      minPickupDate.getMonth(),
      minPickupDate.getDate()
    );
    const _state = {
      ...state,
      deliveryMonth:
        date.getMonth() + 1 < 10
          ? `0${date.getMonth() + 1}`
          : date.getMonth() + 1,
      deliveryDay: date.getDate() < 10 ? `0${date.getDate()}` : date.getDate(),
      deliveryYear: date.getFullYear(),
      selectedDeliveryDate: date,
    };
    // Set the Pickup date if its less than 3 days
    if (state.selectedPickupDate > minPickupDateDate) {
      _state.pickupMonth =
        minPickupDateDate.getMonth() + 1 < 10
          ? `0${minPickupDateDate.getMonth() + 1}`
          : minPickupDateDate.getMonth() + 1;
      _state.pickupDay =
        minPickupDateDate.getDate() < 10
          ? `0${minPickupDateDate.getDate()}`
          : minPickupDateDate.getDate();
      _state.selectedPickupDate = minPickupDateDate;
    }
    // Set the total days
    const differenceInTime =
      _state.selectedDeliveryDate.getTime() -
      _state.selectedPickupDate.getTime();
    const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
    _state.days = differenceInDays;
    _state.extra = await calculateExtras(_state);
    setState(_state);
  };

  const goTo = (_page: string) => history.push(`/${_page}.${lang}.htm`);

  const handleSubmit = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const token = await window.grecaptcha.execute(
      "6LeeIbQZAAAAAFOR_gvWMYTc6TaFq0p2Pr5Fo8zd",
      { action: "submit" }
    );
    const isValid = await fetch(`${host}/index.php?&v=${identity()}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        "g-recaptcha-response": token,
      }),
    });
    const isValidRes = await isValid.json();
    if (isValidRes.success && isValidRes.data) {
      const reservation = {
        ...state,
        ...form,
        total,
      };
      reservation.gps = reservation.gps ? "ΝΑΙ" : "ΟΧΙ";
      // reservation.insurance = (reservation.insurance) ? 'ΝΑΙ' : 'ΟΧΙ';
      if (reservation.insurance === 0) {
        reservation.insurance = locale.gr.step2.insurance1a;
      } else if (reservation.insurance === 1) {
        reservation.insurance = locale.gr.step2.insurance2a;
      } else if (reservation.insurance === 2) {
        reservation.insurance = locale.gr.step2.insurance3a;
      }
      reservation.babyseat = reservation.babyseat ? "ΝΑΙ" : "ΟΧΙ";
      reservation.pickupLocationText =
        locale.gr.step1.text2opt[reservation.pickupLocation];
      reservation.deliveryLocationText =
        locale.gr.step1.text2opt[reservation.deliveryLocation];
      const response = await fetch(`${host}/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          reservation,
          recaptcha: token,
        }),
      });
      const res = await response.json();
      // Add GA Event
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "spaEvent",
        eventCategory: "Reservation",
        eventAction: "Click",
        eventLabel: "Submit",
      });
      if (res.success) goTo("thank_you");
    }
  };

  const moveNext = () => {
    // window.scrollTo(0, 0);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    // Add GA Event
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "spaEvent",
      eventCategory: "Reservation",
      eventAction: "Click",
      eventLabel: `Step: ${step + 1}`,
    });
    setStep(step + 1);
  };

  const movePrevious = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    // Add GA Event
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "spaEvent",
      eventCategory: "Reservation",
      eventAction: "Click",
      eventLabel: `Step: ${step - 1}`,
    });
    setStep(step - 1);
  };

  useEffect(() => {
    getRates();
  }, [state]); // eslint-disable-line

  useEffect(() => {
    getFleetCategory();
  }, []);

  const ReservationHtml = () => (
    <div className={classes.reservation}>
      <div className={classes.reservationModalHeader}>
        {locale[lang].reservation.title}
      </div>
      <div className={classes.reservationModalContentP}>
        <div>{locale[lang].step1.text2}</div>
        <div>{locale[lang].step1.text2opt[state.pickupLocation]}</div>
        <div>{`${String(
          state.selectedPickupDate.toLocaleDateString(
            localeStringMap[lang],
            localOptions
          )
        ).slice(0, 11)} ${state.pickupHour}:${state.pickupMinute}`}</div>
      </div>
      <div className={classes.reservationModalContentP}>
        <div>{locale[lang].step1.text5}</div>
        <div>{locale[lang].step1.text2opt[state.deliveryLocation]}</div>
        <div>{`${String(
          state.selectedDeliveryDate.toLocaleDateString(
            localeStringMap[lang],
            localOptions
          )
        ).slice(0, 11)} ${state.deliveryHour}:${state.deliveryMinute}`}</div>
      </div>
      <div className={classes.reservationModalContent}>
        {`${locale[lang].reservation.extra}: ${state.extra}`}
        &euro;
      </div>
      {fleetCategory.length && (
        <div className={classes.reservationModalContent}>
          {`${locale[lang].step2.category}: ${
            fleetCategory[state.category - 1].title
          }`}
        </div>
      )}
      <div className={classes.reservationModalContent}>
        {`${locale[lang].reservation.days}: ${state.days}`}
      </div>
      <div className={classes.reservationModalFooter}>
        {`${locale[lang].reservation.total}: ${total}`}
        &euro;
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      <Grid item xs={12} className={classes.header}>
        <Stepper activeStep={step} alternativeLabel className={classes.stepper}>
          <Step>
            <StepLabel>{locale[lang].step1.title}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{locale[lang].step2.title}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{locale[lang].step3.title}</StepLabel>
          </Step>
        </Stepper>
      </Grid>
      {step === 0 && (
        <Grid item xs={12} className={classes.content}>
          <Grid container>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                {locale[lang].step1.text1}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              className={`${classes.formGrid} ${classes.formGridFrom}`}
            >
              <FormControl className={classes.formControl}>
                <div>{locale[lang].step1.text2}</div>
              </FormControl>
              <FormControl className={classes.formControl}>
                <Select
                  labelId="pickupLocation"
                  id="pickupLocation"
                  value={state.pickupLocation}
                  name="pickupLocation"
                  onChange={handleChange}
                >
                  {locale[lang].step1.text2opt.map((value, index) => (
                    <MenuItem key={index} value={index}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  locale={localeMap[lang]}
                >
                  <KeyboardDatePicker
                    margin="normal"
                    id="selectedPickupDate"
                    // label={locale[lang].step1.text3}
                    format="dd/MM/yyyy"
                    value={state.selectedPickupDate}
                    onChange={handleChangePickup}
                    autoOk
                    disablePast
                    minDate={pickupDate}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
              <FormControl className={classes.formControlStacked}>
                <Select
                  labelId="pickupHour"
                  id="pickupHour"
                  value={state.pickupHour}
                  name="pickupHour"
                  onChange={handleChange}
                >
                  {[...Array(24)].map((value, index) => (
                    <MenuItem
                      key={index}
                      value={index + 1 < 10 ? `0${index + 1}` : index + 1}
                    >
                      {index + 1 < 10 ? `0${index + 1}` : index + 1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <span style={{ padding: 10 }}>:</span>
              <FormControl className={classes.formControlStacked}>
                <Select
                  labelId="pickupMinute"
                  id="pickupMinute"
                  value={state.pickupMinute}
                  name="pickupMinute"
                  onChange={handleChange}
                >
                  {["00", "15", "30"].map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                className={`${classes.formControl} ${classes.footnote}`}
              >
                {locale[lang].step1.text4}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              className={`${classes.formGrid} ${classes.formGridTo}`}
            >
              <FormControl className={classes.formControl}>
                <div>{locale[lang].step1.text5}</div>
              </FormControl>
              <FormControl className={classes.formControl}>
                <Select
                  labelId="deliveryLocation"
                  id="deliveryLocation"
                  value={state.deliveryLocation}
                  name="deliveryLocation"
                  onChange={handleChange}
                >
                  {locale[lang].step1.text2opt.map((value, index) => (
                    <MenuItem key={index} value={index}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  locale={localeMap[lang]}
                >
                  <KeyboardDatePicker
                    margin="normal"
                    id="selectedDeliveryDate"
                    // label={locale[lang].step1.text3}
                    format="dd/MM/yyyy"
                    value={state.selectedDeliveryDate}
                    onChange={handleChangeDelivery}
                    autoOk
                    disablePast
                    minDate={deliveryDate}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
              <FormControl className={classes.formControlStacked}>
                <Select
                  labelId="deliveryHour"
                  id="deliveryHour"
                  value={state.deliveryHour}
                  name="deliveryHour"
                  onChange={handleChange}
                >
                  {[...Array(24)].map((value, index) => (
                    <MenuItem
                      key={index}
                      value={index + 1 < 10 ? `0${index + 1}` : index + 1}
                    >
                      {index + 1 < 10 ? `0${index + 1}` : index + 1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <span style={{ padding: 10 }}>:</span>
              <FormControl className={classes.formControlStacked}>
                <Select
                  labelId="deliveryMinute"
                  id="deliveryMinute"
                  value={state.deliveryMinute}
                  name="deliveryMinute"
                  onChange={handleChange}
                >
                  {["00", "15", "30"].map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                className={`${classes.formControl} ${classes.footnote}`}
              >
                {locale[lang].step1.text7}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              className={`${classes.formGrid} ${classes.formGridTo}`}
            >
              <ReservationHtml />
            </Grid>

            <Grid
              item
              xs={12}
              className={classes.footer}
              style={{ textAlign: "right" }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={moveNext}
                endIcon={<NavigateNext />}
              >
                {locale[lang].nav.next}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
      {step === 1 && (
        <Grid item xs={12} className={classes.content}>
          <Grid container>
            <Grid item xs={12}>
              {locale[lang].step2.title}
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              className={classes.formGrid}
              style={{ paddingBottom: 30 }}
            >
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  {locale[lang].step2.category}
                </FormLabel>
                <RadioGroup
                  aria-label="category"
                  name="category"
                  value={state.category}
                  onChange={handleChange}
                >
                  {fleetCategory.map((row, index) => (
                    <div key={`${row.fleet_category_id}-${index}`}>
                      <FormControlLabel
                        value={row.fleet_category_id}
                        control={<Radio />}
                        label={`${locale[lang].step2.category} ${row.title} (${row.description})`}
                        className={classes.categoryLabel}
                      />
                      <Grid container spacing={5}>
                        <Grid item xs={12} sm={6}>
                          <Card>
                            <CardActionArea>
                              <CardMedia
                                component="img"
                                alt={`${locale[lang].step2.category} ${row.title}`}
                                height="140"
                                width="100%"
                                src={`images/fleet/${row.img1}`}
                                title={`${locale[lang].step2.category} ${row.title}`}
                              />
                            </CardActionArea>
                          </Card>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Card>
                            <CardActionArea>
                              <CardMedia
                                component="img"
                                alt={`${locale[lang].step2.category} ${row.title}`}
                                height="140"
                                width="100%"
                                src={`images/fleet/${row.img2}`}
                                title={`${locale[lang].step2.category} ${row.title}`}
                                style={{ padding: 0, margin: 2 }}
                              />
                            </CardActionArea>
                          </Card>
                        </Grid>
                      </Grid>
                    </div>
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              className={`${classes.formGrid} ${classes.formGridTo}`}
            >
              <ReservationHtml />
            </Grid>
            {/* <Grid item xs={12} className={classes.formGrid}>
            <FormControl component="fieldset">
              <FormLabel component="legend">insurance2</FormLabel>
              <RadioGroup aria-label="insurance2" name="insurance2" value={state.insurance2} onChange={handleChange}>
                <FormControlLabel value={0} control={<Radio />} label={locale[lang].step2.insurance2a} />
                <FormControlLabel value={1} control={<Radio />} label={locale[lang].step2.insurance2b} />
                <FormControlLabel value={2} control={<Radio />} label={locale[lang].step2.insurance2c} />
              </RadioGroup>
            </FormControl>
          </Grid> */}
            <Grid item xs={12} className={classes.formGrid}>
              <Grid container>
                <Grid item xs={1} className={classes.insuranceCol1}>
                  <Radio
                    checked={state.insurance === 0}
                    onChange={handleChange}
                    value="0"
                    color="default"
                    name="insurance"
                    inputProps={{
                      "aria-label": locale[lang].step2.insurance1a,
                    }}
                  />
                </Grid>
                <Grid item xs={10} className={classes.insuranceCol2}>
                  {locale[lang].step2.insurance1a}
                  <br />
                  {"- "}
                  {locale[lang].step2.insurance1b}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1} className={classes.insuranceCol1}>
                  <Radio
                    checked={state.insurance === 1}
                    onChange={handleChange}
                    value="1"
                    color="default"
                    name="insurance"
                    inputProps={{
                      "aria-label": locale[lang].step2.insurance2a,
                    }}
                  />
                </Grid>
                <Grid item xs={10} className={classes.insuranceCol2}>
                  {locale[lang].step2.insurance2a}
                  <br />
                  {"- "}
                  {locale[lang].step2.insurance2b}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1} className={classes.insuranceCol1}>
                  <Radio
                    checked={state.insurance === 2}
                    onChange={handleChange}
                    value="2"
                    color="default"
                    name="insurance"
                    inputProps={{
                      "aria-label": locale[lang].step2.insurance3a,
                    }}
                  />
                </Grid>
                <Grid item xs={10} className={classes.insuranceCol2}>
                  {locale[lang].step2.insurance3a}
                  <br />
                  {"- "}
                  <b>{locale[lang].step2.insurance3b}</b>
                </Grid>
              </Grid>
              <FormControl component="fieldset">
                <FormGroup aria-label="extras" row>
                  {/* <FormControlLabel
                  checked={!!(state.insurance)}
                  control={<Checkbox color="primary" />}
                  label={locale[lang].step2.insurance}
                  name="insurance"
                  labelPlacement="end"
                  onChange={handleChange}
                /> */}
                  <FormControlLabel
                    checked={!!state.babyseat}
                    control={<Checkbox color="primary" />}
                    label={locale[lang].step2.babyseat}
                    labelPlacement="end"
                    name="babyseat"
                    onChange={handleChange}
                  />
                  <FormControlLabel
                    checked={!!state.gps}
                    control={<Checkbox color="primary" />}
                    label={locale[lang].step2.gps}
                    labelPlacement="end"
                    name="gps"
                    onChange={handleChange}
                  />
                </FormGroup>
              </FormControl>
            </Grid>

            <Grid item xs={6} className={classes.footer}>
              <Button
                variant="contained"
                color="primary"
                onClick={movePrevious}
                startIcon={<NavigateBefore />}
              >
                {locale[lang].nav.previous}
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.footer}
              style={{ textAlign: "right" }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={moveNext}
                endIcon={<NavigateNext />}
              >
                {locale[lang].nav.next}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}

      {step === 2 && (
        <Grid item xs={12} className={classes.content}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {locale[lang].step3.title}
            </Grid>

            <Grid item xs={12} md={8} container>
              <Grid item xs={12} md={6} style={{ paddingRight: 15 }}>
                <FormControl
                  fullWidth
                  className={classes.form}
                  variant="outlined"
                >
                  <TextField
                    required
                    id="firstName"
                    value={form.firstName}
                    onChange={handleChangeForm("firstName")}
                    label={locale[lang].step3.firstName}
                    variant="filled"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountBoxIcon className={classes.icon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  className={classes.form}
                  variant="outlined"
                >
                  <TextField
                    required
                    id="lastName"
                    value={form.lastName}
                    onChange={handleChangeForm("lastName")}
                    label={locale[lang].step3.lastName}
                    variant="filled"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountBoxIcon className={classes.icon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{ paddingRight: 15 }}>
                <FormControl
                  fullWidth
                  className={classes.form}
                  variant="outlined"
                >
                  <TextField
                    required
                    id="flight"
                    value={form.flight}
                    onChange={handleChangeForm("flight")}
                    label={locale[lang].step3.flight}
                    variant="filled"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FlightIcon className={classes.icon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{ paddingRight: 15 }}>
                <FormControl
                  fullWidth
                  className={classes.form}
                  variant="outlined"
                >
                  <TextField
                    required
                    id="telephone"
                    value={form.telephone}
                    onChange={handleChangeForm("telephone")}
                    label={locale[lang].step3.telephone}
                    variant="filled"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PhoneIphoneIcon className={classes.icon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl
                  fullWidth
                  className={classes.form}
                  variant="outlined"
                >
                  <TextField
                    required
                    id="email"
                    value={form.email}
                    onChange={handleChangeForm("email")}
                    label={locale[lang].step3.email}
                    variant="filled"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon className={classes.icon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  className={classes.form}
                  variant="outlined"
                >
                  <TextField
                    id="filled-required"
                    label={locale[lang].step3.comments}
                    value={form.comments}
                    variant="filled"
                    // placeholder="Comments"
                    multiline
                    rows={4}
                    onChange={handleChangeForm("comments")}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MessageIcon className={classes.icon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                {/* <div className="g-recaptcha" data-sitekey="6LeeIbQZAAAAAFOR_gvWMYTc6TaFq0p2Pr5Fo8zd" /> */}
                <Button
                  // variant="outlined"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={
                    !form.firstName ||
                    !form.lastName ||
                    !form.flight ||
                    !form.telephone ||
                    !form.email
                  }
                >
                  {locale[lang].step3.submit}
                </Button>
              </Grid>
            </Grid>

            <Grid item xs={12} md={4}>
              <ReservationHtml />
            </Grid>

            <Grid item xs={12} className={classes.footer}>
              <Button
                variant="contained"
                color="primary"
                onClick={movePrevious}
                startIcon={<NavigateBefore />}
              >
                {locale[lang].nav.previous}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Component;
