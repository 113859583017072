export default {
  index: {
    menu: "PAGINA INIZIALE",
    title: "Benvenuto nell’ azienda Nova Rent a Car",
    subTitle: "Autonoleggio a Salonicco",
  },
  fleet: {
    menu: "FLOTTA",
    title: "FLOTTA",
    subTitle: "",
  },
  about_us: {
    menu: "L' AZIENDA",
    title: "L' AZIENDA",
    subTitle: "",
  },
  terms_and_conditions: {
    menu: "TERMINI E CONDIZIONI",
    title: "TERMINI E CONDIZIONI",
    subTitle: "",
  },
  contact: {
    menu: "COMUNICAZIONE",
    title: "COMUNICAZIONE",
    subTitle: "",
  },
};
