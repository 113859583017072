export default {
  index: {
    menu: "HOME",
    title: "Welcome to Nova Rent a Car",
    subTitle: "Rent your car from Thessaloniki Greece",
  },
  fleet: {
    menu: "FLEET",
    title: "Fleet",
    subTitle: "",
  },
  about_us: {
    menu: "ABOUT US",
    title: "About Us",
    subTitle: "",
  },
  terms_and_conditions: {
    menu: "TERMS & CONDITIONS",
    title: "Terms and conditions",
    subTitle: "",
  },
  contact: {
    menu: "CONTACT",
    title: "Contact us",
    subTitle: "",
  },
};
