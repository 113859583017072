import React from "react";
import { Grid } from "@material-ui/core";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import locale from "./Locale";
import { ContainerProps } from "../types";
import style from "./Style";

// const Container = ({ lang }: any): JSX.Element => (
const Container = ({ lang }: ContainerProps) => (
  <>
    <Header lang={lang} page="terms_and_conditions" />
    <div style={style.container}>
      {locale[lang].map((row) => (
        <Grid container style={style.row} key={row[0]}>
          <Grid item sm={3} xs={12} style={style.col1}>
            {row[0]}
          </Grid>
          <Grid item sm={9} xs={12}>
            {row[1]}
          </Grid>
        </Grid>
      ))}
    </div>
    <Footer lang={lang} page="terms_and_conditions" />
  </>
);

export default Container;
