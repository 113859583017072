import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Card,
  CardContent, // Paper,
} from "@material-ui/core";
import Locale from "./Locale";

const style = {
  offers: {
    backgroundColor: "#f2f2f2",
    padding: 30,
  },
  card: {
    borderColor: "#cccccc",
    header: {
      fontSize: 16,
      backgroundColor: "#e6e6e6",
    },
    content: {
      // minHeight: 140,
    },
  },
  trademark: {
    fontSize: "0.8rem",
  },
};

const Container = (props) => {
  const { lang } = props;
  return (
    <>
      <Grid
        container
        spacing={5}
        // direction="columns"
        // justify="flex-start"
        // align="flex-start"
        // alignItems="stretch"
        // component={Paper}
        style={style.offers}
      >
        <Grid item xs={12} sm={6} md={3} height="100%">
          <Card style={style.card}>
            <CardContent style={style.card.header}>
              {Locale[lang].offer.title}
            </CardContent>
            <CardContent style={style.card.content}>
              <ul>
                <li>{Locale[lang].offer.options1}</li>
                <li>{Locale[lang].offer.options2}</li>
                <li>{Locale[lang].offer.options3}</li>
                <li>{Locale[lang].offer.options4}</li>
                <li>{Locale[lang].offer.options5}</li>
                <li>{Locale[lang].offer.options6}</li>
                <li>{Locale[lang].offer.options7}</li>
              </ul>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card style={style.card}>
            <CardContent style={style.card.header}>
              {Locale[lang].insurance.title}
            </CardContent>
            <CardContent style={style.card.content}>
              {Locale[lang].insurance.text1}
              <ul>
                <li>{Locale[lang].insurance.option1}</li>
                <li>{Locale[lang].insurance.option2}</li>
              </ul>
              {Locale[lang].insurance.text2}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent style={style.card.header}>
              {Locale[lang].equipment.title}
            </CardContent>
            <CardContent style={style.card.content}>
              {Locale[lang].equipment.text}
              <ul>
                <li>{Locale[lang].equipment.option1}</li>
                <li>{Locale[lang].equipment.option2}</li>
                <li>{Locale[lang].equipment.option3}</li>
                <li>{Locale[lang].equipment.option4}</li>
              </ul>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent style={style.card.header}>
              {Locale[lang].services.title}
            </CardContent>
            <CardContent style={style.card.content}>
              {Locale[lang].services.text}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} style={style.trademark}>
          {Locale[lang].trademark}
        </Grid>
      </Grid>
    </>
  );
};

Container.propTypes = {
  lang: PropTypes.string,
};

Container.defaultProps = {
  lang: "en",
};

export default Container;
