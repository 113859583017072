export default {
  text1:
    "Ταξιδεύετε για επαγγελματικούς ή λόγους αναψυχής; Χρειάζεστε να ενοικιάσετε οικονομικό και αξιόπιστο αυτοκίνητο από κάποια συνεπή και έμπειρη εταιρία ενοικιάσεων αυτοκινήτων;",
  text2:
    "Επιθυμείτε τη μεταφορά σας ή του group σας, με ασφάλεια και διακριτικότητα;",
  text3:
    "Η εταιρία NOVA ενοικιάσεις αυτοκινήτων στη Θεσσαλονίκη σας παρέχει την επιλογή που ζητάτε.",
  text4: "Παραλαβή και παράδοση των αυτοκινήτων:",
  text4opt: [
    "στο αεροδρόμιο Μακεδονία της Θεσσαλονίκης,",
    "στο κέντρο της Θεσσαλονίκης,",
    "σε ξενοδοχεία της πόλης,",
    "στα ΚΤΕΛ,",
    "στον ΟΣΕ,",
    "οπουδήποτε ο πελάτης επιθυμεί.",
  ],
  text5:
    "Πολυετής εμπειρία και εξειδικευμένο προσωπικό σε συνδυασμό με νέα άριστα συντηρημένα αυτοκίνητα εξασφαλίζουν την παροχή:",
  text5opt: [
    "Yψηλού επιπέδου υπηρεσιών ενοικίασης αυτοκινήτων,",
    "Transfer services προσώπων από και προς τη Θεσσαλονίκη και το αεροδρόμιο Μακεδονία,",
    "Υπηρεσίες μεταφοράς group προσώπων με minibus(7θέσια, 9θέσια η και περισσοτέρων θέσεων),",
    "Υπηρεσίες μεταφοράς VIP με luxury οχήματα,",
  ],
  text6:
    "Σε εξαιρετικές τιμές, ασφάλεια και διακριτικότητα. Φιλικό, ευγενικό και άρτια εκπαιδευμένο προσωπικό σε συνεχή επαφή μαζί σας έτοιμο να σας εξυπηρετήσει και να σας βοηθήσει σε οτιδήποτε χρειαστείτε και οτιδήποτε προκύψει.",
};
