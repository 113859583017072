export default {
  index: {
    menu: "HEIM",
    title: "Auto Reservierung",
    subTitle: "",
  },
  fleet: {
    menu: "FAHRZEUGE",
    title: "Autos",
    subTitle: "",
  },
  about_us: {
    menu: "WIR ÜBER UNS",
    title: "Wir über uns",
    subTitle: "",
  },
  terms_and_conditions: {
    menu: "Allgemeine Geschäftsbedingungen",
    title: "Vertrag-Allgemeine Geschäftsbedingungen",
    subTitle: "",
  },
  contact: {
    menu: "KONTAKT",
    title: "Kontakt",
    subTitle: "",
  },
};
