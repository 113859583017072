export default {
  step1: {
    title: "RESERVATION",
    text1: "Make your online reservation today!",
    text2: "Pick-up from:",
    text2opt: ["THESSALONIKI AIRPORT", "AFTER AGREEMENT"],
    text3: "Pick-up date:",
    text4:
      "Note: For pick-ups before 08:00 and after 21:00 there is an extra charge of €20.00",
    text5: "Delivery at:",
    text6: "Delivery date:",
    text7:
      "Note: For deliveries before 08:00 and after 21:00 there is an extra charge of €20.00",
  },
  step2: {
    title: "CHOOSE A CAR",
    category: "Category",
    insurance: "Full Insurance Coverage (VOLLKASKO)",
    insurance1a: "Collision damage waiver (CDW) - €600 deductible.",
    insurance1b: "Use of credit card or €600 guarantee",
    insurance2a: "Collision damage waiver (CDW) - €200 deductible.",
    insurance2b: "Use of credit card or €200 guarantee",
    insurance3a:
      "FDW-€0.00 deductible: Renter's maximal liability in case of an accident is reduced to €0.",
    insurance3b: "NO CREDIT CARD OR GUARANTEE IS NEEDED",
    babyseat: "BABY SEAT",
    gps: "GPS (Extra €25.00)",
  },
  step3: {
    title: "REVIEW & BOOK",
    firstName: "FIRST NAME",
    lastName: "LAST NAME",
    flight: "ΑΡΙΘΜΟΣ ΠΤΗΣΗΣ",
    email: "EMAIL",
    telephone: "TELEPHONE",
    comments: "COMMENTS",
    submit: "SEND",
  },
  reservation: {
    title: "YOUR RESERVATION", // panel_header
    total: "Total",
    extra: "Extra charges",
    days: "Days",
  },
  nav: {
    previous: "PREVIOUS",
    next: "NEXT",
  },
  label_title: "TERMS & CONDITIONS",
  label_subtitle: "Car Rentals in Thessaloniki, Greece ",
  label_header1: "RESERVATION",
  label_label1: "Make your online reservation today!",
  label_pickup: "Pick-up from:",
  select_option1: "THESSALONIKI AIRPORT",
  select_option2: "KAVALA AIRPORT (EXTRA CHARGE AFTER AGREEMENT)",
  select_option3: "AFTER AGREEMENT",
  label_pickup_date: "Pick-up date:",
  label_pickup_note:
    "Note: For pick-ups before 08:00 and after 21:00 there is an extra charge of €20.00",
  label_deliver: "Delivery at:",
  label_deliver_date: "Delivery date:",
  label_deliver_note:
    "Note: For deliveries before 08:00 and after 21:00 there is an extra charge of €20.00",
  label_header2: "CHOOSE A CAR",
  label_category: "Category",
  label_header3: "REVIEW & BOOK",
  label_from: "FULL NAME:",
  label_flight: "FLIGHT NUMBER:",
  label_telephone: "TELEPHONE:",
  label_email: "EMAIL:",
  label_comments: "COMMENTS:",
  panel_footer: "Total",
  label_days: "Days",
  label_extra: "Extra charges",
  label_contact_now: "CALL NOW",
  label_newsletter_header: "NEWSLETTER",
  label_newsletter_description:
    "Sign up to our newsletter if you want to be notified per email about the news and offers of Nova Rent a Car",
  label_offer1_header: "RENTAL OFFER FOR ONE WEEK",
  label_offer1_description1:
    "7-days rental of category B (Hyundai Atos, Kia Picanto) total cost €110.00* (FDW insurance included)",
  label_offer1_description2:
    "7-days rental of category B2 (Hyundai Getz, Citroën C3) total cost €125.00* (FDW insurance included)",
  label_offer2: "RENTAL OFFER FOR TWO WEEKS",
  label_offer2_description1:
    "14-days rental of category B (Hyundai Atos, Kia Picanto) total cost €182,00* (FDW insurance included)",
  label_offer2_description2:
    "14-days rental of category Β2 (Hyundai Getz, Citroën C3) total cost €210.00* (FDW insurance included)",
  label_offer_note:
    "* The above offers are valid for rentals from 15/10/2014 to 15/12/2014",
  footer_1_header: "We offer:",
  footer_1_option_1: "Unlimited klms",
  footer_1_option_2: "VAT 24%",
  footer_1_option_3: "Full Damage Waiver (FDW)",
  footer_1_option_4: "Theft Protection Insurance",
  footer_1_option_5: "Additional Driver",
  footer_1_option_6: "24-hours road assistance",
  footer_1_option_7: "Free of charge pick-ups and deliveries at the airport",
  footer_2_header: "The insurance we provide:",
  footer_2_option_1:
    "Optionally we can provide Full Damage Waiver regardless of: ",
  footer_2_option_2: "the vehicle category or",
  footer_2_option_3: "the rental period and the rental duration",
  footer_2_option_4:
    "...so that in case of an accident you will not be charged at all.",
  footer_3_header: "Equipment of our vehicles:",
  footer_3_option_1:
    "All vehicles have 5 doors and are fully equipped as follows: ",
  footer_3_option_2: "Air condition",
  footer_3_option_3: "Power steering",
  footer_3_option_4: "Power windows",
  footer_3_option_5: "CD-player",
  footer_company: "©2009 Nova Rent a Car Ltd.",
  footer_address: "Address: 16th klm Thessaloniki-Peraia, Postal Code: 57500",
  footer_telephone: "Telephone: +30 239 207 3494 - Fax: +30 239 207 3493",
  label_1:
    "Nova rent a car is a well established car rental company that operates in the broader region of Northern Greece since 2003. Its main goal is to provide excellent customer service along with the most competitive prices. By offering you full insurance cover",
  label_2:
    "We are based in Thessaloniki and offer service for all customers at Macedonia Airport as well as anywhere else in the city, thereby helping you avoid any delays that might cause problems to your business trip or holidays.",
  label_3:
    "We offer you services of highest quality and leave to you only the driving…",
  label_4: "How to get to Nova Rent a Car",
  label_5: "View Nova Rent a Car in a larger map",
  label_1_title: "DRIVER’S AGE:",
  label_1_description: "Driver should be at least 23 years old.",
  label_2_title: "DRIVER’S LICENCE:",
  label_2_description:
    "A valid driving license held for at least one year is required.",
  label_3_title: "FINES:",
  label_3_description:
    "Tickets and fines resulting from any violation of the Greek traffic law during rental period are borne solely by the renter.",
  label_4_title: "INSURANCE:",
  label_4_description:
    "All authorised drivers are covered against fire and liability to third parties for death, injury or damage to property.",
  label_5_title: "COLLISION DAMAGE WAIVER (CDW):",
  label_5_description:
    "CDW-€600.00 deductible: Renter's maximal liability in case of an accident is reduced to €600.00. Collision damage waiver insurance is included on all price lists.",
  label_6_title: "FULL DAMAGE WAIVER (FDW):",
  label_6_description:
    "FDW-€0.00 deductible: Renter's maximal liability in case of an accident is reduced to €0. Full damage waiver insurance is optional.",
  label_7_title: "THEFT PROTECTION:",
  label_7_description:
    "Theft protection insurance is included on all price lists.",
  label_8_title: "PERSONAL ACCIDENT INSURANCE:",
  label_8_description:
    "Personal accident insurance is included on all price lists. It covers the driver for loss of life or total permanent disablement for the amount of €15,000.00 and covering the passengers for injuries up to €500,000.00",
  label_9_title: "TAXES:",
  label_9_description: "All prices include 24% VAT.",
  label_10_title: "PICK-UPS AND DELIVERIES:",
  label_10_description:
    "All vehicles are picked-up/delivered according to renter's wish at Macedonia International Airport of Thessaloniki without charge. Charges are applicable on any other location. For pick-ups and deliveries outside working hours the renter will be charged €20.00 per case (working hours are from 08:00 to 21:00).",
  label_11_title: "GASOLINE:",
  label_11_description: "Gasoline consumption is payable by the renter.",
  label_12_title: "BABY SEATS:",
  label_12_description:
    "Available on request, free of charge (maximum one baby seat per vehicle).",
  label_13_title: "VEHICLE AVAILABILITY:",
  label_13_description:
    "Vehicle models are according to original planning. Delivery problems, however, may compel us to supply another vehicle in exceptional cases.",
  label_14_title: "ACROSS THE BORDER RENTALS:",
  label_14_description: "It is strictly prohibited to exit the Greek borders.",
  label_15_title: "FERRYING THE CAR:",
  label_15_description:
    "A written authorisation from NOVA is required in advance.",
  from_and_to_kavala: "Picked from AND Delivered to KAVALA",
  from_or_to_kavala: "Picked from OR Delivered to KAVALA",
  from_and_to_after_hours: "Picked from AND Delivered to AFTER HOURS",
  from_or_to_after_hours: "Picked from OR Delivered to AFTER HOURS",
  more_than_3_days: "Please choose more than 3 days for rental",
  error_invalid_date: "The date you entered is invalid. Please Try again!",
  error_max_days:
    "You have exceeded the maximum limit of days for this period. Please contact us or try again!",
  text1:
    "Nova introduces new services to meet the rising expectations of its customers. We added four new types of Transfer Services with professional drivers and we emphasize on safety, comfort and style of our customers.",
  text2:
    "Do you wish to be transported (you or your group of persons) with safety and discretion?",
  text3: "Nova rent a car Thessaloniki offers you the choice you seek. ",
  text4: "Delivery and collection of the cars at:",
  text4_option1: "Makedonia international Airport of Thessaloniki,",
  text4_option2: "Thessaloniki city centre,",
  text4_option3: "Hotels,",
  text4_option4: "Bus terminal station (KTEL),",
  text4_option5: "Train terminal station (OSE),",
  text4_option6: "Wherever you wish.",
  text5:
    "Experienced and specialized staff, new and well maintained cars ensure the provision of:",
  text5_option1: "High quality rent a car services,",
  text5_option2:
    "Personal transfer services from and to Thessaloniki and Makedonia airport,",
  text5_option3: "Group transfer services with minibuses (7,9 or more seats),",
  text5_option4: "VIP services with or without drivers of luxury cars.",
  text6:
    "Excellent prices/rates, safety and discretion. Friendly, polite staff in constant communication with you, ready to serve you and assist you on any need may arise.",
  will_contact_you:
    "One of our associates will contact you to confirm the reservation.",
  thankyou: "Thank you!",
  reservation_send: "Your Reservation has been send.",
  airport_label: "Airport Service:",
  airport_text:
    "We will be waiting for you at the airport (Macedonia International Airport of Thessaloniki), pick you up and transfer you wherever you want. Take your time to relax, prepare for a meeting and manage your arrangements while we drive you to your destination.",
  p2p_label: "Point to Point Service:",
  p2p_text:
    "Enjoy a stress free ride and let us plan the best route. We provide you a reliable point to point service so that you can relax during the drive.",
  excursion_label: "Excursion Service:",
  excursion_text:
    "Excursion service: Specially organized tours just for you according to your preferences and demands. Your personal driver – guide will be happy to make sure that you will have all the time to relax and enjoy the places you visit.",
  leisure_label: "Full Business – Leisure Service:",
  leisure_text:
    "We will assign you your personal driver for as long as you wish. We collect transfer and wait for you, while we organize (based on your commands) your route and your whole trip if you wish (hotel reservations, tickets, activities etc).",
  reservation_pickup: "Commencement pickup type of service",
  reservation_pickup_option1:
    "Airport service (From Thessaloniki Airport to wherever you want, for as long you want)",
  reservation_pickup_option2:
    "Point to point service (From wherever to wherever)",
  reservation_pickup_option3:
    "Full business - leisure service (We drive you to your destination and we manage all your arrangements such as hotel bookings, theatre tickets, restaurant reservations etc)",
  reservation_pickup_option4:
    "Excursion service (Sightseeing’s tours planned by you or by us. Tour guides provided if needed)",
  reservation_pickup_date: "Commencement pickup date of service",
};
